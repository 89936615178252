//Temporary styles

//.k-toggle { display: none; }
.tmp-issue {
    background-color: lightsalmon; border: 1px dashed red; padding: .5em;
}
.tmp-login-container {
    color: $palette-bgrey; padding: 12px 0; text-align: center;
    position: relative; z-index: $zindex-topmenu-stripe;
    & select { font-family: 'Open-Sans', sans-serif; font-size: 1em; margin: 0 1em; }
}

/* Test functions */

.test-function { color: orangered; font-weight: 600; }
button.mark-as-complete { bottom: 10px; position: absolute; right: 10px; }
.block_carousel button.test-function { bottom: 15px; position: absolute; }

$test-panel-color: lighten( $palette-coaching-2, 33% );
$test-panel-lines: $palette-egrey;
.test_panel_wrap {
    background-color: $test-panel-color; overflow-y: auto; overflow-y: auto; position: fixed; right: 0; width: 100%;
    border-bottom-left-radius: 0; box-shadow: 0 0 16px 4px rgba(0,0,0,.2);
    z-index: 999;
    @media ( min-width: $screen-phone-xxlscreen-min ) { border-bottom-left-radius: 16px; width: 420px; }
}
.test_panel {
    color: black; font-size: 13px; height: 100vh; margin-top: 0; margin-right: 0; opacity: 1; padding: 20px; direction: ltr;
    @media ( min-width: $screen-phone-xxlscreen-min ) { height: auto; }
    .tp_tabs {
       border-bottom: 1px solid $test-panel-lines; margin-right: 40px;
       a { display: inline-block; font-weight: 600; padding: 5px 0; text-transform: uppercase; }
       a:hover { color: $palette-brand-pri1; }
       span { color: $test-panel-lines; margin: 0 10px; }
       .tab-close { 
           align-items: center; background: rgba( $palette-brand-pri1, .1 ); color: $palette-brand-pri1; display: flex; border-radius: 50%; font-size: 22px; height: 40px; justify-content: center; position: absolute; right: 12px; top: 12px; width: 40px;
           &:hover { background: rgba( $palette-brand-pri1, 1 ); color: white; }
       }
    }
    table { border-collapse: collapse; width: 100%; }
    table td { padding: 0 0 5px 0; vertical-align: top; }
    .tp-title { font-weight: 600; margin-bottom: 5px; }
    .tp-divider { border-top: solid 1px $test-panel-lines; line-height: 0; margin: 7px 0 8px; }
    .tp_label { 
        align-items: center; display: flex; flex-flow: row nowrap; justify-content: space-between; margin-bottom: 5px;
        input:not([class*='dx-']) {
            background-color: white; border-color: $test-panel-lines; border-style: solid; border-width: 1px; margin-left: auto; margin-right: 10px; padding: 0 3px; resize: none;
            color: black; font-family: 'Open-Sans', sans-serif; font-size: 1em;
        }
        input[class*='dx-'] { min-height: 10px; padding: 2px 0 2px 5px; }
        
        input[type="date"] { width: 35%; margin-right: 0; }
    }
    button {
        font-family: 'Open-Sans', sans-serif; font-size: 11px; font-weight: 600; text-transform: uppercase;
        width: 64px; border: 2px solid grey; background: lightgrey; }
}
html[lang=ar] .test_panel button { font-family: "Open-Sans"!important; }

.test_panel_toggle {
    align-items: center; background: darken($test-panel-color, 25%); color: darken($test-panel-color, 72.5%); cursor: pointer; display: flex; border-radius: 50%; font-size: 12px; font-weight: 600; height: 40px; justify-content: center; position: fixed; right: 12px; top: 12px; width: 40px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,.2); z-index: 99;
    &:hover {
        background: $palette-brand-pri1; color: white;
    }
}

.toBeAdded {
    border-radius: 4px;
    color: lightgray;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}