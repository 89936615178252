// Import required scss files
@import "fonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";

@import "~devextreme/dist/css/dx.common";
@import "~devextreme/dist/css/dx.light";
@import "colors", "base", "temp", "charts", "insight.results", "navigation", "buttons", "inputs", "devex.mods", "admin", "micro.habits.dropdown", "modals";

/// ANIM ///

@keyframes grow {
	0% { opacity: 0.25; transform: scaleX(.1); }
	50% { opacity: 1; transform: scaleX(1.03); }
	75% { transform: scaleX(.97); }
	100% { transform: scaleX(1); }
} 

g[id^="triangle-group"] g { animation: grow 0.45s; animation-fill-mode: backwards; }
.fade { opacity: 0.3; cursor: default !important; }

//////////////////////// LAYOUT ////////////////////////
/*#root { background: $color-dblue; min-height: 100vh; }*/
.mt--preloader { min-height: 80vh; display: flex; align-items: center; justify-content: center; }
.mt--body { /*background: $color-dblue; */}
.mt--page { background: white; }
.mt--txt { line-height: 1.5; padding: 0; }
.mt--content {
    padding: 2.5rem 0;
    @include  tablet-portrait { padding: 4rem 0; } }
.mt--layout {
    &.padding-header {
       padding: 0;
       @include tablet-landscape {  padding-left: $paddin-sides-loose; padding-right: $paddin-sides-loose; }
    }
    max-width: 1440px; margin-left: auto; margin-right: auto;
    padding-left: $paddin-sides-min; padding-right: $paddin-sides-min;
    @include phone-portrait { padding-left: $paddin-sides-close; padding-right: $paddin-sides-close; }
    @include tablet-portrait {  padding-left: $paddin-sides-loose; padding-right: $paddin-sides-loose; }
}

.mt--three-quarters { width: 100%; @include tablet-landscape { width: 75%; } }
.mt--panel {
    padding: 3em 0.5rem; border-bottom: 2px solid $color-bgrey; 
    .col:first-child { padding-top: 1.5em; }
    &:last-child { border-bottom: none; }
}
.mt--breadcrumb-area {
        align-items: center;
        display: flex;
        font-weight: 600;
        height: 60px;
        .mt--back-link {
            align-items: center;
            color: $color-dgreen;
            display: flex;
            gap: 0.5rem;
            a {
                color: inherit;
            }
            &:hover {
                color: $color-green;
            }
        }
        .mt--breadcrumb {
            align-items: center;
            color: $color-dgreen;
            display: flex;

            a {
                color: inherit;
                &:last-child {
                    color: #CC7400;
                }
                &:only-child {
                    color: $color-dgreen;
                }
            }
        }
    }
///// CONTENT TITLES /////
.mt--title-login { font-size: 2.2rem; color: $color-blue; font-weight: 300; padding-bottom: 0.5rem; }
.mt--block-title, .mt--title, h1, h2 { color: $color-blue; font-size: 1.467rem; font-weight: 600; }
.mt--block-title { padding-bottom: 3rem; align-self: flex-start;  }
.mt--section-title { font-size: 1.6rem; font-weight: 600; letter-spacing: 0.12rem; color: $color-orange; padding: 5rem 0 3rem 0; text-transform: uppercase; }
.mt--title { padding-bottom: 1rem; }
.mt--title:not(:first-child) { padding-top: 2rem; }


///
.mt--paragraph-lead { color: $color-blue; font-size: 1.25rem; line-height: 1.75; margin-bottom: 2rem; }
.mt--pull-quote {
    padding: 1.5em 3em 1.5em 2em; font-size: 1.4667rem; font-weight: 300; line-height: 2;
    color: $color-blue; border-top: 2px solid $color-bluegrey; border-bottom: 2px solid $color-bluegrey;
}
.mt--header {
    min-height: 200px; @include tablet-portrait { min-height: 260px; }
}

.mt--admin-header-wrap {
    border-bottom: 2px solid white;
    .mt--header {
        min-height: unset; height: 100px; @include tablet-landscape {height: 180px;}
        display: flex; flex-direction: column; justify-content: space-between;
        .mt--lead { display: flex; margin-top: auto; padding-bottom: 2rem; }
        .lead-txt {
            min-height: unset;
            .mt--lead-team {
                color: $color-dblue;
                font-size: 2.2rem;
                font-weight: normal;
                letter-spacing: 0;
                text-transform: none;
            }
        }
    }
}
.mt--lead {
    width: 90%; color: $color-orange; font-size: 3rem; font-weight: 300; justify-content: space-between;
    @include tablet-portrait { font-size: 3.867rem; }
    .mt--lead-team {
        font-size: 1rem; font-weight: 600; text-transform: uppercase; letter-spacing: 0.2rem; padding-top: 1rem;
        @include tablet-portrait { font-size: 1.4rem; padding-top: 2rem; }
    }
    .lead-txt {
        min-height: 200px; display: flex; flex-direction: column; justify-content: center;
        @include tablet-portrait { min-height: 260px; }
    }
}
html[lang=ar] .mt--lead { @include tablet-portrait { font-size: 3.5rem; } }
ul.mt--list {
    li { margin-bottom: 1.5rem; display: flex; align-items: baseline; }
    li:last-child { margin-bottom: 0; }
    li:before {
        display: inline-block; margin-inline-end: 0.75rem; min-width: 15px; height: 15px; position: relative; top: 1px;
        background-image: url(../assets/img/outline_octagon_gradient.svg); background-size: cover; content: ""; 
    }
}
.mt--cta {
    width: 100%; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; align-items: center; padding: 5em 0;
    @include phone-landscape { flex-direction: row; }
    &>.col { width: 100%;@include phone-landscape { width: 48%; } }
    &>.col:last-child { justify-content: center; padding: 4rem 0; padding-top: 0; @include phone-landscape { justify-content: flex-end; padding: 4rem 0; } }
    &>.spc { width: 100%; @include phone-landscape { width: 4%; } }
    &.cta-narrow { padding: 5em; }
}
.mt--info-area {
    display: flex; justify-content: space-between; align-items: center; padding: 3rem 0; flex-direction: column; 
    @include phone-landscape { flex-direction: row; } 
    .btn-txt { white-space: nowrap; }
    .mt--txt {
        padding-right: 0; margin-bottom: 1rem; text-align: center; 
        @include phone-landscape { padding-inline-end: 2rem; margin-bottom: 0; text-align: left;  }
    }
}
.mt--img { max-width: 100%; }

// GFX //
.mt--pattern {
    background: url('../assets/img/pattern_octagon_anim.svg'),linear-gradient(to right, #001831, #1A2F5A);
    background-size: 1600px;
    @include tablet-portrait { background-size: 2000px; }
}
.mt--pattern-grey {
    background: url(../assets/img/pattern_admin_grey_tall.png); background-size: 1440px; background-repeat: repeat;
}
    
.mt--pattern-blue {
        background: url(../assets/img/pattern_admin.png); background-size: 820px; background-repeat: repeat;
        .mt--lead div {
            color: #F99806; font-weight: normal;
        }
}
.mt--octa {
    width: 135px; height: 135px; background-image: url(../assets/img/shape_octagon.svg);
    background-repeat: no-repeat; overflow: hidden;
}

.mt--octa-grad {
    width: 120px; height: 120px; background-image: url(../assets/img/outline_octagon_gradient.svg);
    background-repeat: no-repeat; background-position: center; overflow: hidden;
}

.mt--octa-score {
    &[class*="key"] { background: none; }
    div { max-width: 110px; }
    &.low-key {
        path,text { fill: $color-key-low; }
    }
    &.medium-key {
        path,text { fill: $color-key-medium; }
    }
    &.good-key {
        path,text { fill: $color-key-good; }
    }
    &.best-key 
    {
        path,text { fill: $color-key-best; }
    }
}

.mt--img { img { width: 100%; } }
.mt--img { img { width: 100%; } }
/*.mt--vert-ellipsis { color: $color-orange; font-size: 2em; }*/

// MY TEAMS GRID
.my-teams-row {
    .cell-icon {
        svg { color: $color-grey-3; margin-inline-end: 0.5em; }
        .ico { font-size: 1.75rem; }
    }
}
.mt--icon-datagrid { font-size:1.6rem!important; line-height:1.6rem!important; color: $color-grey-3!important; }


/// NOTIFICATIONS
.mt--notification-small {
    background: $color-bgrey; border-color: $color-blue-1; border-style: solid;
    &.warning { background: $color-borange; border-color: $color-orange; svg { color: darken($color-orange, 10%); } }
    &.success { background: $color-bgreen;  border-color: $color-green; svg { color: $color-dgreen; } }
    svg { color: #3A576F; }
}
.mt--notification {
    font-size: 1rem; margin-bottom: 4rem; padding: 1.5rem 2rem; line-height: 1.5; gap: 1.5rem;
    border: none; border-inline-start-style: solid; border-inline-start-width: 5px; background: #ededed;
    display: flex; align-items: center;
    &.success { border-color: $color-green; }
    &.neutral { border-color: $color-blue; }
    &.warning { border-color: $color-orange; }
    .stroke-octagon { width: 36px; height: 36px; }
    
}
.mt--notification-small {
    display: flex; border-width: 1px; font-size: 0.8667rem; padding: 0.5rem 1rem; margin-top: 1rem;
    svg { font-size: 1.2rem; vertical-align: middle; }
    span { margin-inline-start: 0.5rem; }
}
.mt--notification-status {
    display: flex; justify-content: flex-start; width: 100%; margin-left: auto; margin-bottom: 1.5rem; margin-top: 0.5rem; gap: 0.25rem; text-align: start;
    svg { color: $color-orange; }
    @include tablet-portrait { margin-bottom: 0; justify-content: flex-end; }
}

.mt--notification-center {
    width: 100%; margin: 0.5rem auto; font-weight:600; color: $color-dblue;  @include desktop { width: 75%; }
    svg { margin-top: 0.25rem; margin-inline-end: 0.5rem;  }
}

.dx-overlay-content .mt--notification { font-size: 0.933rem; font-weight: normal; padding: 0.75rem; margin-bottom: -1rem; margin-top: 1rem;  }

/// COLUMNS ///
.spc { height: 2em; }

.mt--2-cols {
    width: 100%; 
    display: flex; flex-wrap: wrap; 
    &>.col {
        width: 100%; align-items: center; 
        @include phone-portrait { width: 48%; }
    }
    &.wide-loose {
        &>.col:first-child {  width: 100%;  @include tablet-portrait { width: 63%; }}
        &>.col:last-child  {                @include tablet-portrait { width: 33%; } }
    }
    &.loose-wide {
        &>.col:first-child {  width: 100%;  @include tablet-portrait { width: 33%; }}
        &>.col:last-child  {                @include tablet-portrait { width: 63%; } }
    }
    &.wide-narrow {
        &>.col:first-child { width: 76%; }
        &>.col:last-child  { width: 20%; }
    }
    &.narrow-wide {
        &>.col:first-child { width: 23%;  }
        &>.col:last-child  { width: 73%;  }
    }
    &>.spc { width: 100%; @include phone-portrait{ width: 4%; } }
}
.mt--hide-col-2 {
    display: flex;
    flex-wrap: wrap;
    &>.col:last-child { display: none; }
}

// BREAKPOINT FOR WINDMILL CHART ///
.mt--break-large .mt--content {
    &>.mt--2-cols {
        &>.col { width: 100%; @include desktop { width: 48%; } }
        &>.spc { width: 100%; height: 2em; @include desktop { width: 4%; } }

    }
}
//////// OPTIONS BAR ////////
.mt--options-bar {
    .mt--key-detailed { margin: 0; }
    margin-bottom: 1rem; display: flex; flex-direction: column; justify-content: center; font-size: 16px; align-items: center; 
    min-height: 80px; width: 100%; background: $color-grey-4; color: $color-blue; padding: 1rem;
    border-radius: 8px; font-weight: 600;
    @include phone-portrait { padding: 1rem $paddin-sides-close;  }
    @include tablet-portrait { justify-content: space-between; padding: 0 2rem; flex-direction: row;}
    /*span { margin-right: 0.5em; }*/
    .options-selector { 
        width: 100%; max-width: 650px; display: flex; gap: 1rem; align-items: center; justify-content: center; @include tablet-portrait { width: 60%;  }
        &>div { display: flex; flex-grow: 1; width: 100%; @include phone-portrait { width: 50%; }  }
        .mt--btn { min-width: 100px; background: #E8ECEE; }
        .mt--btn:hover, .mt--btn:hover i { background: white; color: $color-blue; border-color: $color-blue; }
    }
    &.bar-with-radios {
        justify-content: center; flex-direction: column;
         @include phone-landscape { flex-direction: row; justify-content: space-between; } 
        &>div { margin: 0.5rem 0; }
        .key-container { padding-top: 1rem; @include phone-landscape { padding-top: 0; } }
    }
    &.bar-with-dropdown {
        .options-selector { flex-direction: column; @include phone-portrait { flex-direction: row; } }
        .key-container {
            padding-top: 1rem;
            @include tablet-portrait { padding-top: 0; }
            &>div { margin-inline-start: 1rem; }
        }
    }
}

///////////// OVERVIEW (participants) //////////////

.mt--participant-display {
    display: flex; flex-direction: column; justify-content: space-between; text-align: left; width: 100%;
    @include tablet-portrait { flex-direction: row; text-align: center; }
    @include desktop { width: 75%; }
    &>.mt--participant-indicator {
        width: 100%; background: white; padding: 1rem 1.5rem; border-bottom: 2px solid $color-bgrey; 
        display: flex; flex-direction: row; align-items: center; justify-content: flex-start;
        @include tablet-portrait { width: 32%; flex-direction: column; padding: 2rem 1.25rem; border: none; justify-content: center; }
        &>div:first-child { min-width: 42px; @include phone-landscape { margin-bottom: 0.75rem; } }
        .info-group {
            display: flex; flex-direction: column; font-weight: 600; font-size: 1rem; min-height: unset;
            justify-content: space-between; margin-inline-start: 1rem; width: 100%;
            @include tablet-portrait { font-size: 0.8667rem; margin: 0; }
            .title {
                display: inline; text-transform: uppercase; padding-inline-end: 0.5rem;              
                @include tablet-portrait { display: block; padding: 0; }
            }
            .desc { text-transform: none; font-weight: normal; }
            .counter {
                display: flex; font-weight: normal; flex-wrap: wrap; gap: 0.5rem; margin-top: auto; justify-content: flex-start;
                margin-bottom: 0;
                @include tablet-portrait { justify-content: space-around; gap: 2px; margin-top: 1rem; }
                &:empty { margin: 0; }
                .status-not-started { color: $color-traffic-red; }
                .status-in-progress { color: $color-traffic-yellow; }
                .status-completed { color: $color-traffic-green; }
            }

        }
        .mt--bgicon {
            position: relative; width: 3rem; height: 3rem; background-repeat: no-repeat; background-position: center;
            &>img { position: absolute; }
            &.user.grey { background-image:url("../assets/img/fa-user-light-grey.svg"); }
            &.users.grey { width: 3rem; background-image:url("../assets/img/fa-users-light-grey.svg"); @include phone-landscape { width: 4rem; } }
            &.user.blue { background-image:url("../assets/img/fa-user-light-blue.svg"); }
            &.users.blue { width: 3rem; background-image:url("../assets/img/fa-users-light-blue.svg"); @include phone-landscape { width: 4rem; } }
            .ico-circle { width: 1.25rem; height: 1.25rem; right: 0; bottom: -2px; }
        }
        b { font-weight: 600; }
    }
}
.mt--tbl-3-col-desc {
    padding: 0 0 1rem 0.5rem; width: 100%; border-bottom: 1px solid #ddd; color: #727272; 
    display: flex; align-items: baseline; justify-content: flex-end; align-self: flex-end;
}
.mt--email-stats {
    align-items: center; display: flex; flex-flow: row wrap; justify-content: flex-start;
    .mt--email-stat {
        width: 150px;
        span { text-transform: lowercase; }
    }
}

// ALL TEAMS LIST

.mt--completion-grid-display { 
    span {
        margin: 0 2px;
        &:nth-child(1) { color: $color-traffic-green; }    
        &:nth-child(2) { color: $color-traffic-yellow; }    
        &:nth-child(3) { color: $color-traffic-red; }     
    }
}
.mt--completion-status {
    display: flex; justify-content: flex-end; gap: 0.5rem; text-align: right; padding-bottom: 2rem; margin-top: -2rem;
    span {
        &:nth-child(2) { color: $color-grey; }
        &:nth-child(2) { color: $color-traffic-green; }    
        &:nth-child(3) { color: $color-traffic-yellow; }    
        &:nth-child(4) { color: $color-traffic-red; }   
    }
}

// CHECKBOX TOOLBAR

.mt--datagrid-toolbar {
    padding-bottom: 0;
    .toolbar-cols {
        display: flex; flex-direction: column; gap: 2rem; justify-content: space-between; align-items: baseline;
        @include tablet-landscape { flex-direction: row; }
        .column {
            align-self: stretch; display: flex; flex: 1; flex-direction: column; column-gap: 2rem; row-gap: 1rem; justify-content: space-between;
            &:empty { display: none; }
            &.force-row { flex-direction: column; @include phone-landscape { flex-direction: row; } }
            @include phone-landscape { flex-direction: row; }
            @include tablet-landscape { flex-direction: column; }
        }
        .mt--info {
            align-items: flex-start; display: flex; font-size: 1rem; font-weight: normal; justify-content: flex-end;
            @include tablet-landscape { align-items: baseline; }
        }
    }
    .mt--block-title {
         display: flex; padding-bottom: 0;
        .mt--ico { color: $color-orange; cursor: pointer; font-size: 1rem; margin-inline-start: 0.5rem; }
    }
    .mt--notification-status {
        width: auto; margin-top: 0; margin-left: 0;
        &.mt--reminder {
            align-items: flex-start; flex-direction: column; flex: 1;
            display: flex; gap: 2rem; justify-content: space-between; margin-top: auto;
            @include phone-portrait { align-items: center; flex-direction: row-reverse; }
            @include tablet-landscape { flex: unset; flex-direction: row; justify-content: flex-end; }
            span { display: flex; align-items: baseline; max-width: unset;  @include tablet-landscape { max-width: 300px; } }
        }
        button { min-width: 170px; }
    }
}
.mt--team-list {
    .mt--datagrid {
        border-top: 2px solid #ECEFF1;
        .dx-datagrid-headers + .dx-datagrid-rowsview { border-top: none!important;}
    }
   
}
.mt--button-container {
    display: flex; gap: 1rem;
    &>div { display: flex; gap: 3rem; }
}
.mt--btn { display: flex; justify-content: center; margin: 0; i { color: white; } }

.btn-grid-add {
    align-self: flex-start; background: $color-orange; border: none; color: white; max-width: unset;
}
.mt--share-wrap {
    align-items: flex-start; display: flex; flex: 1; flex-direction: row; justify-content: flex-start; row-gap: 1.75rem; column-gap: 1rem;
    @include phone-landscape { align-items: flex-end; justify-content: flex-end; }
}

.mt--share-controls {
    align-items: center; display: flex; flex-direction: row; justify-content: flex-start; flex-wrap: wrap;
    column-gap: 2rem; row-gap: 1.5rem; padding-left: 1.5rem;
    @include phone-landscape { flex-direction: column; align-items: flex-end; flex-wrap: nowrap; justify-content: flex-end; padding-left: 0; }
    @include tablet-landscape { flex-direction: row; align-items: center; }
}

.mt--share-checkbox-container {
    display: flex; flex-direction: row; gap: 1rem;
    & > div {
        display: flex; gap: 0.5rem;
        .dx-checkbox {
            top: 0;
            .dx-checkbox-container { display: flex; align-items: center; }
            &:before { font-size: 1rem; }
        }
    }
}

// FILE UPLOEADER TOOLBAR
.mt--participant-import {
    flex: 1;
    .drag-and-drop { flex: 1; align-self: center; }
    .zone {
	    align-items: center; 
	    border: 2px dashed $color-grey-4;
	    border-radius: 20px;
	    display: flex;
	    flex-direction: column;
	    height: 74px;
	    justify-content: center;
	    padding: 20px;
        text-align: center;
    } 
    .file {
	    background: linear-gradient(to bottom, #EEE, #DDD);
	    border-radius: 20px;
	    display: flex;
	    height: 120px;
	    width: 120px;
	    position: relative;
	    z-index: 10;
	    flex-direction: column;
	    justify-content: center;
    }
    .info {
        color: $color-grey;
        font-size: 0.88rem;
        text-align: center;
        a { display: inline-block; } 
        .mt--btn-linkalike { cursor: pointer; display: inline; }
        svg { color: $color-orange; margin-inline-end: 0.5rem; }
    } 
    .size {
	    background-color: $color-bgrey;
	    border-radius: 3px;
	    margin-bottom: 0.5em;
	    justify-content: center;
	    display: flex;
    } 
    .name {
	    font-size: 12px;
        margin-bottom: 0.5rem;
    } 
    .progressBar {
	    bottom: 14px;
	    position: absolute;
	    width: 100%;
	    padding-left: 10px;
	    padding-right: 10px;
    } 
    .zoneHover {
	    border-color: $color-bgrey;
    } 
    .default {
	    border-color: $color-grey;
    } 
    .remove {
	    height: 23px;
	    position: absolute;
	    right: 6px;
	    top: 6px;
	    width: 23px;
    } 
}
.mt--participant-import { display: flex; flex-direction: column; margin-bottom: 2rem; gap: 1rem; }

///// QUESTIONNAIRE /////
.question-container, .question-headfoot {
    display: flex; padding: 1.5rem 0 1.5rem 1.5rem;  @include tablet-portrait { padding-left: 1rem; }
    &>div:nth-child(1) { width: 80%; @include tablet-portrait { width: 45%;  } }
    &>div:nth-child(2) { width: 80%;  @include tablet-portrait { width: 40%; } }
    &>div:nth-child(3) { width: 20%; justify-content: center; text-align: center; @include tablet-portrait { width: 15%; } }
}
.question-headfoot-sticky {
    border-bottom: 2px solid $color-bgrey; flex-flow: column; position: sticky; top: 0;
    font-weight: 600; background: white; z-index: 19; font-size: 0.8667rem;
    .question-headfoot {
        padding: 1em 0 0 1.5rem; @include tablet-portrait { padding-left: 0; }
        .statements { display: none; align-items: flex-end; padding-top: 1.1rem; @include tablet-portrait { display: flex; } }   
    }
    .question-headfoot .values-helpers {
        .helpers, .values { display: flex; justify-content: space-between; }
        .helpers {
            &>div:nth-child(1) { text-align: left; }
            &>div:nth-child(2) { text-align: center; }
            &>div:nth-child(3) { text-align: right; }
        }
        .values { font-weight: normal; margin: 1em 0; position: relative; left: 5px; }
    }
    .cant-say { width: 10%; text-align: center; align-self: flex-start; }
    &.last { 
        border: none; border-top: 2px solid $color-bgrey;
        /*.cant-say { align-self: flex-start; }*/
    }
}
.mt--questionnaire .question-container {
    align-items: center;
    .question { padding-right: 1.5em; padding-bottom: 1rem; @include tablet-portrait { padding-bottom: 0; } }
    &:nth-child(odd) { background: $color-bgrey; }
    .question-values {
        display: flex; align-items: center; position: relative; padding: 1em 0;
        .range-box {
            width: 100%; height: 10px; background: #F2F5F6; 
            &.range-box-cant-say { opacity: 0.5; }
        }
        .range-fill {
            background: $color-dblue; /*opacity: 0.8;*/ position: absolute; height: 10px; z-index: 2;
        }
        .ticks {
            position: absolute; width: 100%; display: flex; justify-content: space-between; z-index: 1;
            .tick {
                background: $color-grey-5; width: 1px; height: 20px; position: relative;
                &.tick-cant-say { opacity: 0.25; }
            }
        }
    }
    .question-range { width: 100%; position: absolute; z-index: 9; }
    .question-checkbox { display: flex; align-items: center; }
}

///////// RADAR CONTROLL ///////////
.radar-selector {
    padding: 1rem;
    border: 2px solid $color-grey-1;
    @include phone-portrait { padding: 2rem; }
}
.mt--input-group {
    &>div {
        width: 50%; display: flex; flex-wrap: nowrap; margin-right: 4rem; margin: 0.25rem 0; gap: 0.5rem;
        @include phone-landscape { width: 33%; }
        label { font-weight: 600; color: $color-blue; }
        input { min-width: 18px; top: 2px; }
        div { font-weight: 600; color: #0f3250; }
    }
    label { vertical-align: top; cursor: pointer; white-space: nowrap; }
}
//Language modifiers
html[lang=ar] .mt--input-group > div { width: 50%; }
html[lang=el] .mt--input-group > div {
    width: 100%;
    @include phone-landscape { width: 50%; }
}
html[lang=el] .mt--input-group > div:nth-child(4),
html[lang=ar] .mt--input-group > div:nth-child(3)  {
    order: 1;
}

.radar-tbl-legend {
    width: 100%; padding: 1rem; color: $color-blue; font-weight: 600; border: 2px solid $color-grey-1;
    & > span:first-child { width: 100%; color: $color-txt-lgrey; font-weight: 600; font-size: 0.8667rem; margin-bottom: 0.5rem; }
    div:last-child { width: auto; }
    & > div { align-items: center; }
}

/// FOOTER ///
.mt--pattern-footer {
    background-image: none; background-color: $color-dblue; background-position-x: 25%; background-repeat: no-repeat; 
    @include phone-landscape { background-image: url(../assets/img/pattern_footer_wide.svg); }
    @include tablet-portrait { background-position-x: 23%; }
    @include tablet-landscape { background-position-x: 15%; }
    @include desktop { background-position-x: 0; }
}
.mt--footer {
    color: white; font-size: 0.875em; padding-top: 2rem ; padding-bottom: 2rem;
    display: flex; flex-flow: wrap column; align-items: center; justify-content: center;
    @include phone-portrait { justify-content: space-between; align-items: flex-start; flex-direction: row; }
    .txt-legal {
        opacity: 0.33; align-self: center;
        @include phone-portrait { margin-inline-start: auto; align-self: flex-end; }
    }
    .mt--footer-brand {
       /* display: none;*/ padding-top: 0; margin-right: 0;
        @include phone-landscape { display: block; margin-inline-end: 4em; }
        img { margin-bottom: -0.5rem; }
    }
}
.mt--footer-nav {
    justify-content: center;
    @include phone-portrait { align-items: flex-start; flex-direction: row; justify-content: flex-start; }
    a { color: white; opacity: 0.671; padding: 0.5em 0.3em; margin: 0; display: none; }
    a:hover { opacity: 1; cursor: pointer; }
    button { color: white; opacity: 0.671; padding: 0.5em 0.3em; margin: 0; display: none; }
    button:hover { opacity: 1; }
    .col { /*min-height: 7rem;*/ min-width: initial; display: flex; flex-direction: column; width: initial; @include phone-landscape {min-width: 130px; width: 50%; } }
    .col:last-child { margin-left: 1.5rem; @include phone-portrait { margin-left: 3em; } }
}

// ETC
.key-container {
    display: flex; flex-direction: row; font-size: 12px;
    .key-item {
        color: white; font-weight: 600; text-align: center; white-space: nowrap; text-transform: uppercase;
        padding: 0.25em 1em 0.25em 0;
    }
    h4.key-item { color: $color-grey }
    div.key-item { min-width: 25px; letter-spacing: 0.25em; }
}

.low-key { background-color: $color-key-low; }
.medium-key { background-color: $color-key-medium; }
.good-key { background-color: $color-key-good; }
.best-key { background-color: $color-key-best; }

////// LOGIN PAGE /////

.mt--login {
    .mt--login-area .mt--layout {
        padding: 0 $paddin-sides-close;
        @include phone-landscape { padding: 0 $paddin-sides-loose; }
        @include tablet-portrait { padding: 0 6rem; }
    }
    background-color: $color-grey-1; background-image: none;
    background-repeat: no-repeat; background-position:   600px -260px; background-size: 8500px; 
    @include phone-landscape    { background-image:url(../assets/img/shape_login.svg); }
    @include tablet-landscape   { background-position-x: 700px; }
    @include desktop            { background-position-x: 800px; }
    @media ( min-width: 1800px ){ background-position-x: 1150px; }
    .mt--min-height { min-height: 80vh; }
    .mt--flex {
        display: flex;
        .col-login {
            width: 100%; padding: 4rem 0; max-width: 500px; justify-content: center;
            @include phone-portrait { width: 100%;  padding: 6rem 0; } 
            @include phone-landscape { width: 75%;  padding: 8rem 0; } 
            @include tablet-portrait { width: 60%; max-width: 500px; }
            p { margin: 0.25rem 0; }
        }
    }
    .mt--login-form {
        padding-top: 2rem; align-items: center;     
        .mt--input-wrap {
            display: flex; padding: 0.5rem 1rem; background: white; border-radius: 4px;
            input { border: none; font-size: 1rem;  }
            input:focus { border: none; outline: none; }
            svg { margin-inline-end: 0.5rem; color: $color-blue; }
            .mt--btn { height: 36px; }
            .mt--btn:hover svg { color: white; }
        }
        .mt--language-selector {
            background: #d8dee3;
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
        }
    }
    .mt--footer { height: 120px; padding-bottom: 0.5rem; justify-content: center; @include phone-portrait { justify-content: initial; } }
}
html[dir=rtl] .mt--login {
    background-position:   -1980px -260px;
    @include tablet-landscape   { background-position-x: -1750px; }
    @include desktop            { background-position-x: -1400px; }
    @media ( min-width: 1800px ){ background-position-x: -1150px; }
}
//////////// ICO //////////
.mt--ico-cog { width: 52px; height: 36px; background-image: url(../assets/img/ico-cog.svg); }

.mt--key-detailed {
    width: 35%; margin-bottom: 2rem; color: white; text-transform: uppercase; font-weight: 400;
    .key { min-width: 70px; height: 30px; text-align: center; padding: 0.25rem; font-size: 13px;
            display: flex; flex: 1; align-items: center; justify-content: center;
    }
    .txt { margin-bottom: 5px; }
}

html[lang=ar] {
    .mt--key-detailed .key {
        font-size: 15px; line-height: 0;
    }
}

///////// TEXTUAL PAGES /////////////

.mt--dial.ico-flip { transform: scaleX(-1); }       // RTL flip

.mt--recommendations, .mt--team-journey, .mt--howto {
    .mt--content { padding-top: 5rem; }
}
.mt--howto {
    p:not([class]) { padding: 0; font-size: 1.0667rem; line-height: 1.55;  color: $color-txt-lgrey; }
    p:not([class]) + p:not([class]) { padding-top: 1rem; }
    ul.mt--list {
        color: $color-txt-lgrey; font-size: 1.0667rem; margin: 2rem 0; padding-inline-start: 1rem;
        @include phone-portrait { padding-inline-start: 2rem; }
    }
    h1 { font-size: 1.4rem; padding: 3rem 0 2rem 0; line-height: 1.25; }
    h2 { font-size: 1.4rem; padding: 3.2rem 0 2rem 0; font-weight: 600;  }
    .mt--2-cols.narrow-wide {
        .col:first-child { width: 20%; }
        .spc { width: 2%; }
        .col:last-child  { width: 78%; }
    }
}

.mt--recommendations, .mt--team-journey {
    h1 { font-size: 1.4rem; padding: 1.6rem 0; }
    h2 { font-size: 1.4rem; padding-bottom: 3.2rem; font-weight: 600; }
    h2.mt--mb-auto { margin-bottom: 1.5rem; @include tablet-landscape { margin-bottom: auto; } }
    h4 { font-size: 1.0667rem; color: #333333; margin-bottom: 0.5rem; }
    p:not([class]) { padding: 1rem 0; font-size: 1.0667rem; line-height: 1.6;  color: $color-txt-lgrey; }
    p:first-child, p:last-child { padding: 0; }

    .mt--habit-name, .mt--habit-name-with-icon {
        color: $color-blue; text-transform: uppercase; font-size: 1.3333rem; font-weight: bold;  letter-spacing: 0.1rem;
        display: flex; gap: 0.75rem; align-items: flex-start;
        .mt--dial { width: 20px; height: 20px; }
        div { align-self: center; }
    }
    .mt--habit-name-with-icon { margin-bottom: 1rem; }
    .mt--scores {
        margin-bottom: 1rem;
        h2.score-title { padding: 0 0 3rem 0; color: $color-txt-lgrey; }
        .mt--2-cols {
            padding: 2.2rem 1rem; flex-direction: column; flex-wrap: nowrap;
            @include phone-landscape { flex-direction: row; }
            &:not(:last-child) { border-bottom: 2px solid $color-bgrey; }
            &:last-child { padding-bottom: 0; }
            &:first-child { padding-top: 0; } 
        }
    }

    .mt--improvements {
        margin-bottom: 1rem;
        .mt--2-cols {
            padding: 2.2rem 1rem; margin-bottom: 1rem; border: 1px solid #B4B9BC;
            &.mt--two-thirds { width: 66%; }
            &.narrow-wide {
                .col:first-child { width: 100%; @include phone-landscape { width: 12%; } }
                .col:last-child {
                    width: 100%;
                  /*  p {margin-inline-start: 0.5rem;}*/
                    @include phone-landscape { width: 78%; } }
            }
        }
        .mt--dial { width: 110px; height: 110px; }
    }

    .mt--2-cols {
        &.narrow-wide {
            &.col { border: 1px solid red; }
            &>.col:first-child {
               width: 100%; min-width: 120px; display: flex; justify-content: center;
               @include phone-landscape { width: 12%; }
            }

            &>.col:last-child  {
                width: 100%; display: flex; /*flex-grow: 1;*/ flex-direction: column; align-items: center;
                @include phone-landscape { width: 84%; align-items: flex-start; }
            }
            &>.spc { width: 100%; @include phone-landscape { width: 2%; } }
        }
        &.mt--tbl-radar-wrap .col { width: 100%; @include tablet-landscape { width: 48%; } }
        .tbl-wrapper { width: 100%; margin-bottom: 1rem; }
        .col { align-items: flex-start; }
    }
    .col p { padding: 0; }

    ///
    .mt--radar {
        width: 100%; height: 100%; display: flex; justify-content: center; overflow: hidden;
        @media print { width: 340px; margin: auto; margin-top: -1.5rem; }
        svg { width: 85%; height: 85%; transform: rotate(-22.5deg); @media print { height: 100%; width: 100%; } }
    }
    
    .mt--tbl-radar-wrap {
        flex-direction: column;
        @include tablet-landscape { flex-direction: row; }
    }
    
    .mt--key-detailed {
        display: flex; align-items: center; margin-bottom: 4rem; color: white; text-transform: uppercase; 
        .key { min-width: 70px; height: 28px; text-align: center; padding: 0.25rem; font-size: 13px;
               display: flex; align-items: center; justify-content: center;
        }
        div:nth-child(2) { left: 88px; }
        .txt { margin-bottom: 5px; }
    }
    .mt--octa { width: 120px; height: 120px; display: flex; align-items: center; justify-content: center; }

}


html[lang=ar] .mt--recommendations, .mt--team-journey {
    .mt--habit-name, .mt--habit-name-with-icon{
        font-size: 1.4rem;
    }
}
html[lang=ar] .mt--recommendations h2.variable-spacing {
    padding: 3rem 0;
}
/// JOURNEY BOX
.mt--journey-wrap {
    display: flex; flex-direction: column; justify-content: space-between; gap: 0;
    @include tablet-landscape { flex-direction: row; gap: 1.5rem; }
}

.mt--journey-container {
    display: flex; width: 100%; flex-grow: 1; flex-direction: column; justify-content: space-around;
    border-radius: 4px; padding: 1rem 0.5rem; font-size: 0.8rem; text-transform: uppercase; 
    @include phone-portrait { padding: 1rem; }
    @include tablet-portrait { flex-direction: column; border: none; }
    @include tablet-landscape { padding: 2rem 1rem; width: auto; flex-wrap: nowrap; flex-direction: row; border: 1px solid #B4B9BC; }
    &.mt--fill-blue {
        margin-bottom: 2rem; @include tablet-landscape { margin-bottom: 0; }
        li {background: inherit;}
    }
    li {
        display: flex; flex-direction: row; flex-basis: 0; flex-grow: 1; align-items: center;
        text-align: left; padding: 1.5rem; border: 1px solid #B4B9BC; margin: 0; margin-bottom: 2rem;
        background: white;
        @include tablet-landscape { max-width: 110px; text-align: center; padding: 0 0.75rem; flex-direction: column; margin: 0; border: none; background: transparent; }
        @include desktop { max-width: 120px; padding: 0 1rem }
        .mt--dial {
            max-width: 50px; order: 0;
            @include phone-portrait { max-width: 60px; margin-inline-end: 2rem; } 
            @include phone-landscape { max-width: 80px; }
            @include tablet-landscape { max-width: 120px; margin: 1.5rem 0; order: 1; }
        }
        .mt--habit-name {
            color: $color-blue; justify-content: flex-start; order: 1; width: 100%;
            letter-spacing: initial; text-transform: uppercase; font-size: 0.86rem; font-weight: bold;
            margin-left: 1rem;
            @include phone-portrait { font-size: 1.1rem; margin-left: 0; }
            @include phone-landscape { font-size: 1.4rem; }
            @include tablet-portrait { min-width: unset; letter-spacing: 0.05rem; }
            @include tablet-landscape { letter-spacing: 0.1rem; justify-content: center; font-size: 0.8rem; min-height: 35px; min-width: 110px; width: unset; }
            @include desktop { min-width: 140px; }
        }
        &:last-child { margin-bottom: 0; @include tablet-landscape {  } }
    }

    .status {
        color: $color-txt-lgrey; justify-self: flex-end;  text-align: end;
        font-weight: 600; order: 2; font-size: 0.8rem; width: 100%;
        @include phone-portrait { font-size: 1.1rem; }
        @include phone-landscape { font-size: 1.4rem; }
        @include tablet-landscape { justify-self: center; text-align: center; font-size: 0.8rem; }
    }
}

html[lang=ar] .mt--journey-container li .mt--habit-name {  @include tablet-landscape {font-size: 1rem;} }
html[lang=ar] .mt--journey-container li .status {  @include tablet-landscape {font-size: 1rem;} }

.mt--improvements .mt--dial * { fill: $color-orange; }

.mt--recommendations .mt--pectus-wrap {
    &>:first-child { width: 100%; @include tablet-landscape { width: 33%; } }
    &>:nth-child(2) { width: 100%; @include tablet-landscape { width: 67%; } }
    .pectus-divider {
        position: absolute; width: 100%; top: 49%; line-height: 3; color: $color-txt-lgrey; font-weight: 600;
        display: none;
        @include tablet-landscape { display: block; }
    }
    .dashed-line {
        width: 100%; height: 2px; background-image: url(../assets/img/dashed_line.png);
        @include tablet-landscape { width: 285%; }
    }
}
.mt--recommendations .mt--illustration img {
    width: 66%;
}

.mt--overview-area {
    .mt--block-title { width: 100%; padding-bottom: 1rem; @include tablet-portrait { width: auto; padding-bottom: 3em; } }
    .mt--three-quarters { width: 100%; @include tablet-portrait { width: 75%; } }
    /*.mt--notification-status { text-align: left; @include phone-landscape { text-align: right; } }*/
}

.mt--pectus_container {
    width: 100%; font-weight: 600; font-size: 12px; margin: 0 0 0 auto;
    background-image: url(../assets/img/dashed_line.png); background-repeat: repeat-x; background-position: center;
    svg text { fill: $color-blue; }
    @include tablet-landscape { background-image: none; }
}

/////////    PRINT  //////////

@media print {
    .hide-print { display: none; }
    html { font-size: 11px !important; }
    html[lang=ar] { font-size: 11.3px !important; }
    html[lang="ar"] body { font-family: "Almarai", Arial; }
    hr { height: 0.5px!important; }
    .mt--layout { padding: 0!important; }
    .mt--content { padding: 0!important; }
    .pdf-title { font-size: 1.8rem!important; }
    .mt--recommendations p:not([class]) { line-height: 1.5!important; color: black!important; }
    .tbl-wrapper td { color: black; }

    html[lang=ar] { .mt--recommendations p:not([class]) { line-height: 1.8; } }
    html[lang=ar] { mt--paragraph-lead { line-height: 1.8; } }
    .mt--section-title { padding-top: 3rem; } 

    .mt--pectus-wrap { flex-wrap: nowrap; .dashed-line { background-position-x: 4px; } }
    .mt--recommendations .mt--pectus-wrap .pectus-divider { display: block; top: 19px; position: relative; }
    html[lang=el] .mt--recommendations .mt--pectus-wrap .pectus-divider { top: 27px; }
    html[lang=ar] .mt--pectus_container svg text { font-size: 20px; }
    .mt--pectus_container { position: relative!important; top: -30px!important; }
    
    html[lang=ar] .mt--recommendations .mt--radar { margin-top: -5rem; }

    .mt--radar svg { width: 100%!important; }
    .mt--recommendations .mt--pectus-wrap > :nth-child(2) { width: 60%!important; }
    .mt--journey-container, .mt--2-cols narrow-wide { page-break-inside: avoid; }
    .spc { width: 4%!important; }
    .mt--2-cols {
        flex-direction: row!important; flex-wrap: nowrap!important; border-width: 0.5px!important;
        .col { width: 48%!important; }
        &.narrow-wide>.col:first-child { width: 17%!important;  }
        &.narrow-wide>.col:last-child  { width: 79%!important; align-items: flex-start!important;  }
    }
    .page-break { page-break-before: always!important; }
    .page-break-inside-avoid { page-break-inside: avoid!important; }
     /*html[lang=ar] .radar-tbl-legend > div span:first-child { font-size: 11px; }*/ 
    .mt--tbl-radar-wrap .mt--key-detailed { margin-bottom: 0!important; } 
    .mt--key-detailed {
        margin-bottom: 7rem!important;
        .key { font-size: 8px!important; min-width: 45px!important; height: 20px!important; }
    }
    html[lang=ar] .mt--key-detailed .key { font-size: 13px!important; }
    .mt--recommendations .mt--pectus-wrap > div:first-child { width: 40%; }
    html[lang=ar] .mt--recommendations .mt--pectus-wrap > div:first-child { top: -8px; }
    /*html[lang=ar] .mt--recommendations .mt--pectus-wrap .habit-name { font-size: 24px; }*/
    .mt--octa-score > div { min-width: unset!important; }
    .mt--octa-score > div { min-width: unset!important; }

    .mt--journey-wrap {
        flex-direction: row!important; grid-gap: 2.5rem;
        .mt--journey-container{ margin:0!important; }
    }
    .mt--journey-container li .mt--dial { max-width: 30px!important; }
    .mt--improvements {
        & > div { align-items: center!important; /*padding: 2rem 0!important;*/ }
        p { padding-inline-end: 3rem!important; }
        .mt--dial { width: 80px!important; }
    }
    .mt--journey-container li .mt--habit-name { min-width: 100px!important; }

    .mt--recommendations {
        .mt--scores {
            .mt--2-cols {
                padding: 2.2rem 1rem!important; flex-direction: column; flex-wrap: nowrap;
                border-bottom: 2px solid $color-bgrey!important;
               /* &:last-child { padding-bottom: 2.2rem!important; }
                &:first-child { padding-top: 2.2rem!important; }*/
            }
             &>div>div:last-child .mt--2-cols {border-bottom: none!important; padding-bottom: 0!important;}
        }
    }
}