/// NAVIGATION///
#desktopNavbarNav { position: relative; /*right:0;*/ }
.mt--navbar-user {
    margin-inline-start: 2rem; display: none; text-align: start;  padding: 0.5rem 1rem; font-size: 2em;

    &.d-expanded { background: $color-orange; color: white; }
    &.d-collapsed { background: white; color: $color-orange; }

    .mt--hamburger { }
    @include tablet-landscape { display: block; }

}
.mt--navbar-user ul.mt--navigation {
    position: absolute; right: -15px; margin: 0; padding: 2px; background: $color-orange; color: $color-dblue;
    margin-top: 0.5rem; min-width: 250px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px;
    svg { width: 20px; }
    .mt--language {
       background: #FFDEAB; padding: 0; padding-bottom: 0.5rem; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; 
        li { background: #FFDEAB; margin: 0 0.5rem; padding: 0.25rem 0; display: flex; gap: 0.25rem; align-items: center; font-weight: 400; border-radius: 0;
             &:not(.item-not-link):hover {
                 background: #ff990080; // $color-orange 50%;
             }
        }
    }
    li,a { font-weight: 600; color: $color-dblue; }
    li:not(.item-not-link):hover,a:hover { background: $color-bgrey; }
    li {
        background: white; margin-bottom: 2px; display: flex; gap: 0.75rem; align-items: center;
        font-size: 1rem;
        &:first-child { border-top-left-radius: 4px; border-top-right-radius: 4px; }
        &:last-child { margin-bottom: 0; /*border-bottom-right-radius: 4px; border-bottom-left-radius: 4px;*/ }
        span {
            padding-inline-start: 0.25rem;
            &:empty { padding-inline-start: 1.75rem; }
        }
        .link { display: block; padding: 0.75rem 1rem; width: 100%; display: flex; align-items: center; gap: 1rem; }
    }
    li.item-not-link, div.item-not-link {
        font-size: 0.8667rem; cursor: default; border-radius: 0;
        padding: 0.75rem; margin-inline-start: 0; padding-bottom: 0.5rem;
    }
    
   
}
html[dir="rtl"] .mt--navbar-user ul.mt--navigation {
    left: -17px; right: unset;
}

html[lang=ar] .mt--navbar-user ul.mt--navigation {
    font-family: "Almarai";
}

.mt--navbar-nav {
    .mt--language {
        background: #FFDEAB; color: $color-blue; gap: 0.75rem; padding: 1rem; margin-bottom: 1.5rem; border-bottom: 2px solid $color-orange;
        li {
            display: flex; gap: 0.25rem; align-items: center; cursor: pointer;
            span {
                padding-inline-end: 0.25rem;
                &:empty { padding-inline-end: 1.25rem; }
            }
            
        }
    }
}
.mt--navbar-user .mt--btn-corner.active { background: inherit; color: inherit; &:before{display: none;} }
.mt--navbar {
    display: flex; min-height: unset; font-size: 1rem; flex-flow: column nowrap; 
    @include tablet-landscape { flex-flow: row nowrap; justify-content: flex-start; align-items: center; min-height: 80px; }
    .mt--logo-wrap {
        width: auto; padding: 1rem $paddin-sides-min;
        @include phone-portrait { padding: 1rem $paddin-sides-close; }
        @include tablet-landscape { padding: 1rem 0; }
    }
    .mt--navbar-toggler {
        margin-inline-start: auto; margin-inline-end: $paddin-sides-min; border: none; background: transparent;
        font-size: 1.75rem; color: $color-orange;
        .mt--hamburger {
            background: white; color: $color-orange; width: 42px; height: 42px;
            display: flex; align-items: center; justify-content: center;
        }
        &.selected .mt--hamburger, .mt--hamburger.d-expanded { background: $color-orange; color: white; }
        @include tablet-landscape { display: none; }
    } 
    .progress-container { display: none; @include tablet-landscape { display: block; } }
}
 .mt--navbar-collapse {
     flex-basis: 100%; flex-grow: 1; z-index: 9;
     @include tablet-landscape { display: flex; flex-basis: auto; }
     &.collapsed { display: none; /*@include tablet-portrait { display: flex; }*/ }
     &.expanded { display: block; @include tablet-landscape { display: none; } }
     &.d-collapsed { display: none; @include tablet-landscape { display: none; }  }
     &.d-expanded { display: none; @include tablet-landscape { display: block; } }
     
     &.mobile {
         position: relative;
         &.d-collapsed { display: none;  }
         &.d-expanded { display: block; @include tablet-landscape { display: none; } }
         .mt--navigation { top: 13px; right: -8px; }
         
     }
 }
.navbar-admin .mt--navbar-toggler.mobile { font-size: 1rem; }

.mt--navbar-nav { padding-inline-start: 0; margin-bottom: 0; gap: 0;  }
.mt--navbar-brand { display: block; /*margin: 0 auto 0 0;*/ }

.mt--navbar {
    .mt--user-role-display {
        display: flex; margin-inline-start: 0; padding: 1rem $paddin-sides-min;

        .mt--user-role {
            position: absolute; right: -5px; top: 2px; text-transform: uppercase;
            background: $color-orange; color: white; border-radius: 4px; padding: 0 2px;
            min-width: 16px; height: 16px; font-weight: 600; font-size: 12px;
            display: flex; align-items: center; justify-content: center;
        }
        .mt--role-icon { display: flex; align-items: center; justify-content: center; position: relative; margin-inline-end: 1rem; }
        svg[data-icon="octagon"] { color: $color-bluegrey; width: 40px; height: 40px; opacity: 0.66; }
        svg[data-icon="user"] { color: $color-bgrey; width: 18px; height: 18px;  }

        .mt--user-info { color: $color-dblue; font-size: 14px; display: flex; flex-direction: column; align-content: center; line-height: 1.2; margin-top: 2px; }
        .mt--user-info div {
            &.info-wrap {
                font-size: 0.8rem; color: $color-grey;
                div:first-child:after {
                    display: inline-block; margin-right: 0.25rem;
                    @include tablet-landscape { display: none; }
                }
            }
        }

        @include phone-portrait { padding: 1rem $paddin-sides-close; }
        @include tablet-landscape { padding: 1rem 0; margin-inline-start: auto; }
               
    }
    .mt--top-navbar { border-bottom: 2px solid $color-bgrey; @include tablet-landscape { border: none; } }
}


@media (min-width: $screen-tablet-landscape-min) and (max-width: $screen-tablet-xxlscreen-min) {
    .mt--logo-wrap {
        /*display: none;*/
        &.logo-admin {
            display: block;
        }
    }

}

@include tablet-landscape {
    .mt--navbar .mt--navbar-nav { flex-direction: row; gap: 1rem; }
    .mt--navbar-brand { margin-inline-end: 2.5em; }
    .mt--navbar-brand img { min-width: 150px; }
}
.mt--navbar-nav > ul > .nav-link, .mt--navbar-nav > li > .nav-link {
    padding: 1rem; display: flex; align-items: center; gap: 1rem; font-weight: normal;
    &.active { background: white; color: $color-dblue; }
    @include tablet-landscape {
        padding: 0.5em 2em 0.5em 1.5em;
        &.active { padding: 0.5em 2em 0.5em 1.5em; }
    }   
}
.mt--nav-item {
    border-bottom: 2px solid $color-orange; padding-inline-start: 0; @include tablet-landscape { border-bottom: 0; }
    & a { color: $color-blue; }
    &:first-child { border-top: 2px solid $color-orange; }
    &>li { 
        display: block;
        .nav-link { background: transparent; color: black; padding: 0.5rem 2rem 0.35rem 2.5rem; display: flex; align-items: center; }
        .active {
            color: $color-dblue; padding-inline-start: 1rem;
            &:before {
                display: inline-block; content: ""; width: 1rem; height: 1rem; margin-inline-end: 0.5rem;
                background-image: url("../assets/img/arrow-right.svg"); background-repeat: no-repeat; background-size: contain;
            }
            &.flip:before { transform: scaleX(-1); }
        }
        @include tablet-landscape {
            .active { background: $color-orange; }
        }
    }
    &>li:nth-child(2) a { padding-top: 0.25rem; }
    &>li:last-child { border-bottom: none; }
    &>li:last-child a { padding-bottom: 1rem; }
    &:not(.active) > li a {  background: white; color: $color-dblue; }
    a:hover, div:hover { cursor: pointer; }
}
@include tablet-landscape {
    .mt--nav-item {
        &>li { display: none; }
        &:first-child { border: none; }
    }
    .mt--navbar-nav > ul > a, .mt--navbar-nav > li > a { padding-inline: 1.5rem 2rem; padding-top: 0.5em; padding-bottom: 0.5em ; }
}
/// 
.mt--navbar-secondary {
    display: none; padding-bottom: 1.5rem; min-height: initial;
    @include tablet-landscape { display: flex; font-size: 1rem; }
    .nav-link { margin-right: 1em; } 
    .mt--nav-item { border-bottom: none; }

    ul.admin-view {
        display: flex;
        gap: 0.75rem;
        @include tablet-landscape { grid-gap: 1rem; }
    }
}

.mt--diag-status-bar {
    align-items: center;
    display: flex;
    font-size: 1.06667rem;
    height: 80px;
    justify-content: space-between;
    width: 100%;
    .diag-action {
        color: $color-blue;
        cursor: pointer;
        font-weight: 600;
        margin-left: 0.5rem;
        &:hover {
            color: $color-bluegrey;
        }
    }

}
