////// GENERAL DEVEXTREME OVERRIDES  //////
.dx-widget, .dx-widget input { font-family: Open-Sans; font-size: 1rem; }
html[lang=ar] .dx-widget, html[lang=ar] .dx-widget input  { font-family: "Almarai"; }
html[lang=ar] .dx-widget .dx-texteditor-input { height: auto; }
html[lang=ar] .dx-box-item-content { font-size: 16px; }
.dx-texteditor.dx-state-focused.dx-editor-outlined {
    border-color: $color-blue;
    &.dx-state-hover { border-color: $color-blue; }
    .dx-texteditor-input-container::after { color: $color-dgreen; }
}
.dx-checkbox {
    border: 2px solid #B0BBC5; border-radius: 4px; 
    &.dx-checkbox-checked { background: $color-blue; } 
    .dx-checkbox-icon { color: white; background-color: transparent; border: none!important; }
    &.dx-state-hover { border-color: #7b8d9d; }
}

//POPUP - (outside of page wrappers)
.dx-popup-normal { max-width: 480px; }
html[lang=el] .dx-popup-normal { max-width: 480px; @include phone-portrait {min-width: 480px; max-width: 600px;}}
.dx-popup-wrapper > .dx-overlay-content { border: none; border-radius: 4px; }
.dx-popup-title { background-color: $color-dblue; color: white; font-weight: 300; padding: .5rem 2rem; }
/*.dx-popup-content .dx-scrollable-container { padding: 0 1rem; }
.dx-scrollable-simulated .dx-scrollable-container { padding: 0; }*/
.dx-popup-content .dx-scrollbar-vertical { right: -20px; }

//DATAGRID
.dx-datagrid .dx-toolbar-items-container { margin-bottom: 1.5rem; }
.dx-datagrid-headers { color: $color-blue; }

.dx-datagrid-edit-popup {
    .dx-popup-content {
        direction: ltr;
        /*padding: 1.5rem 2rem 0 2rem;*/
        .dx-form-group-caption { color: $color-dblue; width: fit-content; }
        /*.dx-form-group-content { padding-top: 2rem; border-top: 2px solid $color-bgrey;}*/
        .dx-field-item:not(.dx-first-row) { padding-top: 1.5rem !important; }
        .dx-field-item.form-info svg { margin-inline-end: 0.5rem; }
        /*.dx-field-item-label-location-left { padding-right: 3rem; }**/
    }

/*    .dx-button:not(.dx-closebutton) {
        color: $color-dblue; background: white; border: 2px solid $color-orange;
        text-align: center; font-weight: 600; text-transform: uppercase; border-radius: 4px; cursor: pointer;
        .dx-button-content { padding: 0.4rem 1rem; }
        &:hover { color: $color-orange; }
    }*/
    
    .dx-closebutton i { color: white; }
    .dx-toolbar.dx-popup-bottom {
        margin-top: -20px;
        .dx-toolbar-after {
            display: flex; justify-content: center; width: 100%;
            /*.dx-toolbar-item { padding-inline-end: 2rem; }*/
        }
    }
}

.dx-popup-bottom .dx-toolbar-button {
    padding-inline-end: 10px!important;
}

.dx-datagrid {
        .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
            .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after
            { border: 0; }
        .dx-datagrid-focus-overlay {display: none;}
        .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons {
            min-width: 0;
            width: 0;
        }
}

.mt--team-details-dxgrid { 
    /*.dx-header-row {
        td { color: $color-lgrey;}
    }*/

    .dx-datagrid-rowsview, .dx-datagrid-headers .dx-datagrid-table .dx-row > td
    {
        border: none!important;
    }
    .dx-datagrid-rowsview .dx-row, .dx-datagrid .dx-column-lines > td {
        border-left: none !important; border-right: none !important; border-bottom: 1px solid $color-bgrey;
        padding-top: 1.75em !important; padding-bottom: 1.75em !important; vertical-align: middle !important;
    }
}
.mt--datagrid.sharing .dx-header-row  td:nth-child(n+4) { color: $color-blue; font-weight: 600; }

//PUBLIC
.mt--public {
    .dx-toolbar-items-container { margin-bottom: 1.5rem; }
    .mt--datagrid {
        padding-bottom: 2rem;
        overflow-x: auto;
        .dx-datagrid { min-width: 640px; }
        &.wide {
            .dx-datagrid { min-width: 900px; }
        }
    }
    .mt--icon-datagrid { color: $color-orange !important }
    .mt--icon-datagrid:hover { color: $color-orange !important }
    .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) {  
        background: $color-bgrey; cursor: pointer;  
    }
    .dx-datagrid-headers, .dx-datagrid-header-panel { /*border-bottom: none!important;*/ font-weight: 600; }
    .dx-datagrid .dx-column-lines {
        &>td {
            vertical-align: middle !important;
            padding-left: 1rem; padding-right: 1em; padding-top: 1rem !important; padding-bottom: 1rem !important;
            border-bottom: 2px solid $color-bgrey !important;
            border-left: none !important; border-right: none !important; border-top: none !important;        
        }
        &:not(.dx-header-row) {
                td { padding-top: 1.75em !important; padding-bottom: 1.75em !important; vertical-align: middle !important; }
                /*&:hover { background: $color-bgrey; cursor: pointer; }*/
        }
    }
}

.mt--login {
     .mt--language-selector {
         .dx-dropdowneditor-active .dx-dropdowneditor-icon {
             background: transparent;
         }
         .dx-selectbox.dx-state-hover .dx-dropdowneditor-icon {
             background: transparent;
         }
        .dx-selectbox-container {
            background: transparent;
        }
        .dx-dropdowneditor {
            background: none;
        }
        .dx-dropdowneditor-icon {
            background: transparent;
            font-size: 1.625rem;
            opacity: .5;
        }
     }
}
// BUTTON Overrides
.dx-icon { color: $color-orange; }
.dx-button:not(.dx-closebutton) {
    font-size: 0.7rem; font-weight: bold; padding: 0 2em; 
    height: 42px; border: 1px solid $color-blue; border-radius: 2px;
    .dx-button-content { display: flex; align-items: center; justify-content: center; padding: 0; }
    .dx-button-text { color: $color-blue; font-family: Open-Sans; font-size: 0.7rem; font-weight: bold; text-transform: uppercase; letter-spacing: 1px; }
    &.dx-state-focused { background: white; }
    &.dx-state-hover { background: $color-orange !important; color: white !important; border-color: $color-orange; }
    &.dx-state-hover i, &.dx-state-hover .dx-button-text { color: white !important; }
    .dx-icon { font: 15px/15px DXIcons; width: 15px; height: 15px; margin-right: 0.5rem; color: $color-blue; }
}
html[lang=ar] .dx-button:not(.dx-closebutton) .dx-button-text { font-size: 0.8667rem; font-family: "Almarai", Arial; font-weight: 600; }
.mt--datagrid.alt-btn-header {
    .dx-button { background: $color-orange; border: none; color: white; }
    .dx-button-text { color: white;  }
    .dx-icon { color: white; }
}
.dx-calendar-navigator .dx-button { border: none !important; }

//////////////////////
//////  ADMIN  ///////
//////////////////////

/* Input */

.mt--disabled-display .dx-widget { background-color: $color-bgrey; border-color: transparent; opacity: 1; }

.mt--disabled-display { /* Combo */
    .dx-state-disabled.dx-widget { 
        background-color: $color-bgrey; border-color: transparent; opacity: 1; 
        .dx-dropdowneditor-icon { visibility: hidden; }
    }
}
.mt--radiogroup {
    display: flex; flex-grow: 1; align-items: center;
    .dx-radiogroup { margin-left: 8px; }

}

.mt--label-icon .dx-field-item-label-text,.mt--caption-icon .dx-form-group-caption {
    display: flex; position: relative; cursor: pointer;
    
    &:after, &:hover:after {
        content: ""; background-image: url("../assets/img/fa-info-circle.svg"); background-size: contain; background-repeat: no-repeat;
        display: inline-block; width: 1rem; height: 1rem; margin-inline-start: 0.5rem; cursor: pointer;
    }
    &:hover:before {
        content: "Future feature"; background-color: antiquewhite; color: black; display: flex; align-items: center; justify-content: center;
        text-align: center; position: absolute; line-height: 1.5;
        border-radius: 6px; border-bottom-left-radius: 0; padding: 4px 12px; 
        font-size: 12px; font-weight: normal; top: -28px; z-index: 99;
    }
}

.mt--caption-icon .dx-form-group-caption {
    &:before {}
    &:after { margin-top: 0.5rem; }
}
.mt--caption-icon.mt--caption-disabled, .mt--label-icon.mt--caption-disabled {
    .dx-form-group-caption, .dx-field-item-label-text  { color: #CCCCCC !important; }
    
}

.mt--dx-customize-item .dx-field-item-label-text {
    display: flex; align-items: center; cursor: pointer;
    &:before {
        content: ""; background-image: url("../assets/img/fa-info-circle.svg"); background-size: contain; background-repeat: no-repeat;
        display: inline-block; width: 1rem; height: 1rem; margin-inline-end: 0.5rem;
    }
}

.mt--admin {
    //icon in grid
    .mt--datagrid .dx-datagrid .dx-link.mt--icon-datagrid {
        display: inline-flex!important; align-items: center; justify-content: center; color: $color-dgrey; font-size: 15px !important;
        border: 1px solid $color-bgrey; border-radius: 2px;
        padding: 0px !important;
        margin-top: 2px!important;
        &:hover { background: #F5F5F5; outline: 2px solid #F5F5F5; }
    }
    .mt--datagrid .dx-dropdowneditor-icon {
        color: #959595;
    }
    .mt--datagrid .dx-datagrid-table .dx-row > td {
        border-right: none !important; 
    }
    .mt--datagrid td a, .mt--datagrid td .link { color: $color-blue; cursor: pointer; text-decoration: underline; &:hover { color: #206bac; } }
    .mt--icon-datagrid { color: $color-orange !important; border: none!important }
    .mt--icon-datagrid:hover { color: $color-orange !important }

    .mt--datagrid .dx-datagrid .dx-link.mt--icon-datagrid.mt--icon-inverse {
        color: white!important; background: $color-orange; 
        display: inline-flex!important; align-items: center; justify-content: center;
        font-size: 16px!important; font-weight: 700; width: 20px; height: 20px;
        border-radius: 50%; border: 3px solid white!important; margin-inline-end: 2px; margin-inline-start: 0;
        &:hover { border-color: #e0e0e0!important; }
    }
}

// ADMIN DASHBOARD DXGRID EDIT POPUPS

.mt-admin-list-popup {
     /*& + .dx-toolbar.dx-popup-bottom { padding-top: 1.5rem!important; }*/
     .dx-form-group-caption { font-size: 1.06667rem !important; font-weight: 600; letter-spacing: -0.0125rem; line-height: 2; }
     .dx-form-group-content { margin-top: 2px; }
     .dx-field-item-label-location-left { padding-right:0!important; }
}
.dx-field-item-required-mark { display: none; }

// RTL MODIFIERS //

html[dir="rtl"] .mt--preloader .dx-loadindicator-icon { right: 15%; }

//GENERAL DEVEX DATAGRID RESPONSIVITY
/*$border-color: #eee;
.dx-datagrid-headers { display: none; @include tablet-portrait { display: block; }}
.dx-datagrid-table {
    table-layout: auto;  @include tablet-portrait { table-layout: fixed; }*/
/*tr { display: block; @include tablet-portrait { display: table-row; border: 0;} }*/
/*td { display: flex; justify-content: space-between;
		align-items: flex-end;
        border: none!important;*/
/*border-bottom: 1px solid $border-color;*/
/*font-size: .8em;*/
/*line-height: 1.35em;*/
/*@include tablet-portrait { display: table-cell; } 
    }
}*/