/*/// BREAKPOINT VARS ///*/
$screen-phone-xxlscreen-min: 480px;
$screen-phone-landscape-min: 640px;
$screen-tablet-portrait-min: 800px;
$screen-tablet-landscape-min: 1024px;
$screen-desktop-limit-width: 1080px;
$screen-tablet-xxlscreen-min: 1280px;
$screen-desktop-max-width: 1440px;

// Layout block padding values
$paddin-sides-min: 0.5rem;
$paddin-sides-close: 1.5rem;
$paddin-sides-loose: 3rem;
$paddin-sides-wider: 5rem;
// Z-index level values
$zindex-dropdown-menu: 9;
$zindex-topmenu-stripe: 10;
$zindex-popover: 100;

/// MIXINS ///
@mixin phone-portrait { @media (min-width: 480px) { @content; } }
@mixin phone-landscape { @media (min-width: 640px) { @content; } }
@mixin tablet-portrait { @media (min-width: 800px) { @content; } }
@mixin tablet-landscape { @media (min-width: 1024px) { @content; } }
@mixin desktop { @media (min-width: 1280px) { @content; } }
/* CSS reset */
html { box-sizing: border-box; font-size: 14px;
       @include phone-portrait { font-size: 15px; }
}
*, *:before, *:after { box-sizing: inherit; }
b { font-weight: 600; }
body { font-weight: normal; margin: 0; padding: 0; }
h1 { margin: 0; padding: 0; }
h2, h3, h4, h5, h6 { font-weight: 600; margin: 0; padding: 0; }
img { height: auto; vertical-align: middle }
.mt--img-retina img { max-width: 100%; }
body, input { color: black; font-family: 'Open-Sans', sans-serif; }
html[lang="ar"] body { font-family: "Almarai", Arial; }
input:not([type="radio"]) { width: 100%; }
input[type="range"] { margin:0; }
button { cursor: pointer; font-family: 'Open-Sans', sans-serif; outline: none; border: none; background: transparent; }
html[lang=ar] button {
    &:not(.mt--navbar-user):not(.mt--navbar-toggler) { font-family: "Almarai", Arial; font-size: 0.8667rem; }
}
a, a:link, a:visited { color: $palette-mgrey; text-decoration: none; }
a:active, a:hover { color: $color-dblue; }
a.mt--link-blue {color: cyan; text-decoration: underline; }
select::-ms-expand { display: none; }
p { margin: 0; }
hr { background: $color-bgrey; border: none; height: 2px;  margin-block-start: 3.2rem; margin-block-end: 3.2rem; }
hr.dark { background: #96A5B2; }

// ARABIC TYPOGRAPHY RESETS //
html[dir=rtl] * { letter-spacing: initial !important; }
html[lang=ar] { font-size: 17px; }

/// RESETS ///
ul { margin: 0; list-style: none; }
a { text-decoration: none; }
*:not(.test_panel) button { appearance: none; }

//Layout Utils
.mt--max-width { max-width: $screen-desktop-max-width; }
.mt--min-height { min-height: 70vh; }

.mt--full { width: 100%; }
.mt--half { width: 100%; @include tablet-landscape { width: 66%; } @include desktop { width: 50%; } }
.mt--three-quarters { width: 100%; @include tablet-landscape { width: 75%; } }
.mt--one-third { width: 33%; }
.mt--two-thirds { width: 100%; @include tablet-landscape { width: 75%; } @include desktop { width: 66%; } }

.mt--h-100 { height: 100%; }

.mt--txt-nowrap { white-space: nowrap; }

///Autofill////
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/// UTILS ///
.mt--ext-line-height { line-height: 1.5; }
.mt--show-xs { display: block; @include phone-portrait { display: none; } }
.mt--hide { display: none }
.mt--invisible { visibility: hidden; }
.mt--bold { font-weight: 600; }
.mt--text-center { text-align: center; }
.mt--bring-front { z-index: 99; }

.mt--flex { display: flex; }
.mt--flex-wrap { flex-wrap: wrap; }
.mt--flex-nowrap { flex-wrap: nowrap; }
.mt--flex-col { flex-direction: column; }
.mt--flex-gr-1 { flex-grow: 1; }
.mt--flex-sr-1 { flex-shrink: 1; }
.mt--align-content-center { align-content: center; }
.mt--align-content-around { align-content: space-around; }
.mt--align-center, .mt--align-items-center { align-items: center; }
.mt--align-items-start { align-items: flex-start; }
.mt--align-items-end { align-items: flex-end; }
.mt--align-items-base { align-items: baseline; }

.mt--align-self-start { align-self: flex-start; }
.mt--align-self-center { align-self: center; }
.mt--align-self-end { align-self: flex-end; }
.mt--justify-content-center { justify-content: center; }
.mt--justify-content-start { justify-content: flex-start; }
.mt--justify-content-end { justify-content: flex-end; }
.mt--justify-content-between { justify-content: space-between; }
.mt--justify-content-around { justify-content: space-around; }

.mt--justify-self-end { justify-self: flex-end; }
.mt--justify-align-end { align-self: flex-end; }
.mt--flex-between { display: flex; justify-content: space-between; }

.mt--gap-05 { gap: 0.5rem; }
.mt--gap-1 { gap: 1rem; }

.mt--m-0 { margin: 0 !important }
.mt--m-auto { margin: auto!important; }
.mt--ml-auto { margin-left: auto!important; }
.mt--mr-auto { margin-right: auto!important; }
.mt--mx-auto { margin-left: auto!important; margin-right: auto!important; }
.mt--my-auto { margin-top: auto!important; margin-bottom: auto!important; }
.mt--mt-auto { margin-top: auto; }
.mt--mb-auto { margin-bottom: auto; }

.mt--mx-1 { margin-right: 1em; margin-left: 1em; }
.mt--mx-2 { margin-right: 2em; margin-left: 2em; }
.mt--my-0 { margin-top: 0!important; margin-bottom: 0!important;}
.mt--my-1 { margin-top: 1em; margin-bottom: 1em;}
.mt--my-2 { margin-top: 2em; margin-bottom: 2em;}
.mt--my-3 { margin-top: 3em; margin-bottom: 3em;}
.mt--my-5 { margin-top: 5em; margin-bottom: 5em;}

.mt--mt-0 { margin-top: 0!important;}
.mt--mt-05 { margin-top: 0.5em;}
.mt--mt-1 { margin-top: 1em;}
.mt--mt-2 { margin-top: 2em;}
.mt--mt-3 { margin-top: 3em;}
.mt--mt-4 { margin-top: 4em;}
.mt--mt-5 { margin-top: 5em;}
.mt--mr-025 { margin-inline-end: 0.25rem; }
.mt--mr-05 { margin-inline-end: 0.5rem; }
.mt--mr-1 { margin-right: 1em; }
.mt--mr-2 { margin-right: 2em; }
.mt--mr-3 { margin-right: 3em; }
.mt--mb-0 { margin-bottom: 0!important; }
.mt--mb-05 { margin-bottom: 0.5em!important; }
.mt--mb-1 { margin-bottom: 1em!important; }
.mt--mb-2 { margin-bottom: 2em; }
.mt--mb-3 { margin-bottom: 3em; }
.mt--mb-5 { margin-bottom: 5em; }
.mt--ml-0 { margin-left: 0!important }
.mt--ml-025 { margin-inline-start: 0.25em; }
.mt--ml-05 { margin-inline-start: 0.5em; }
.mt--ml-1 { margin-left: 1em; }
.mt--ml-15 { margin-left: 1.5em; }
.mt--ml-2 { margin-left: 2em; }
.mt--ml-3 { margin-left: 3em; }
.mt--ml-5 { margin-left: 5em; }

.mt--p-0 { padding: 0!important; }
.mt--p-1 { padding: 1em; }
.mt--p-2 { padding: 2em; }
.mt--p-3 { padding: 3em; }
.mt--px-0 { padding-left: 0!important; padding-right: 0!important; }
.mt--px-05 { padding-left: .5em; padding-right: .5em; }
.mt--px-1  { padding-left: 1em; padding-right: 1em; }
.mt--px-2  { padding-left: 2em; padding-right: 2em; }
.mt--px-3  { padding-left: 3em; padding-right: 3em; }
.mt--py-0 { padding-top: 0!important; padding-bottom: 0!important; }
.mt--py-1 { padding-top: 1em; padding-bottom: 1em; }
.mt--py-2 { padding-top: 2em; padding-bottom: 2em; }
.mt--py-2-desktop { padding-top: 0; bottom: 0; @include tablet-landscape { padding-top: 2em; padding-bottom: 2em; } }
.mt--py-3 { padding-top: 3em; padding-bottom: 3em; }
.mt--py-4 { padding-top: 4em; padding-bottom: 4em; }
.mt--py-5 { padding-top: 5em; padding-bottom: 5em; }

.mt--pt-0 { padding-top: 0!important; }
.mt--pt-05 { padding-top: 0.5em; }
.mt--pt-1 { padding-top: 1em!important; }
.mt--pt-2 { padding-top: 2em!important; }
.mt--pt-3 { padding-top: 3em!important; }
.mt--pt-5 { padding-top: 5em!important; }
.mt--pr-0 { padding-right: 0!important; }
.mt--pr-1 { padding-right: 1em; }
.mt--pr-2 { padding-right: 2em; }
.mt--pr-3 { padding-right: 3em; }
.mt--pb-0 { padding-bottom: 0!important }
.mt--pb-05 { padding-bottom: 0.5em; }
.mt--pb-1 { padding-bottom: 1em; }
.mt--pb-2 { padding-bottom: 2em!important; }
.mt--pb-3 { padding-bottom: 3em; }
.mt--pb-5 { padding-bottom: 5rem; }
.mt--pl-1 { padding-left: 1em; }
.mt--pl-2 { padding-left: 2em; }
.mt--pl-3 { padding-left: 3em; }

.mt--pl-9 {  padding-left: 9em; }

.mt--b-1-desktop { border: none; @include tablet-landscape { border: 1px solid #B4B9BC; } }
.mt--bb-0 { border-bottom:0 !important }
.mt--b-0 { border:0 !important }

.mt--pos-abs { position: absolute; }
.mt--pos-rel { position: relative; }

.mt-shift-r {
    left: 17px;
}

//vspacing
.vspace05 { height:.5em; } 
.vspace10 { height: 1em; } 
.vspace20 { height: 2em; }
.vspace30 { height: 3em; } 
.vspace40 { height: 4em; }

// OLD STUFF //
.txt-right { text-align: right; }
.show-sm { display: none; }
.flex { display: flex; }






