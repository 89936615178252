
.mt--control-wrap { justify-content: flex-start; max-width: 500px; }
.mt--dropdown { position: relative; border-bottom: 2px solid #B0BBC5; width: 100%; }

.drop-btn {
    background-color: white; border-radius: 4px; padding: 1rem 1rem 1rem 1.5rem; font-size: 16px; border: none; cursor: pointer;
    justify-content: space-between; align-items: center;
    &.show {  border: 2px solid $color-blue; border-bottom: none; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }
    &.drop-with-minibar {
        display: flex; 
        & > div {
            width: 100%;
            &>div:first-child { min-width: 100px; margin-inline-end: 1rem; color: $color-dblue; font-weight: bold; text-transform: uppercase; }
            &>.dropdown-minibar { flex-grow: 1; }
        }
        .mt--ico { width: 5%; }
    }
}
.dropdown-content {
    background: #CFD6DC; padding: 5px; border-radius: 8px; display: none; margin-top: 0.25em;
    position: absolute; overflow: auto; width: 100%; z-index: 15; margin-top: 0;
    border-top-left-radius: 0; border-top-right-radius:0;
    & > div { display: flex; padding: 16px; background: white; border-radius: 4px; border-bottom: 1px solid #CFD6DC;
        cursor: pointer;
        &:hover { background: #e0e7ec; }
        & > div:first-child { min-width: 130px; max-width: 130px; margin-inline-end: 1rem; font-weight: bold; text-transform: uppercase; }
    }
    &.show { display: block; border: 2px solid $color-blue; border-top: none; }
    .dropdown-instruction { color: $color-txt-lgrey; font-size: 13px; font-weight: 600; margin-bottom: 4px; }
    .dropdown-instruction:hover { background: white; cursor: default; }

}
