///////////// CHECKBOX /////////////
input[type="checkbox"] {
    appearance: none; -webkit-appearance: none; font-size: 15px;
    padding: 4px; position: relative; margin: 0;
    width: 1.2em; height: 1.2em; background: white; border: 2px solid #B0BBC5; border-radius: 4px;
}

input[type="checkbox"]:checked {
    background-color: $color-blue; border-width: 1px;
    &:hover { cursor: pointer; }
}
input[type="checkbox"]:focus, input[type=range]:focus {
    outline: none;
}
input[type="checkbox"]:before {
    content: ""; width: 10px; height: 7px; border-bottom: 3px solid white; border-left: 3px solid white;
    color: white; position: absolute; top: 3px; left: 3px; transform: rotate(-55deg);
}
input[type="checkbox"]:not(:checked):hover {
    border: 2px solid #7b8d9d;
    cursor: pointer;
}

input[type="checkbox"]:disabled {
    border-color: $color-bgrey;
    &:hover { cursor: default; border-color: $color-bgrey; }
}

///////////// RADIO BUTTON ///////////////////
.radio-group { display: flex; }
input.in-radio, input.in-radio:checked { appearance: none; border: none; }
input.in-radio:checked:before { border-radius: 50%;}

input.in-radio:checked + label,
input.in-radio:not(:checked) + label { position: relative; padding-left: 28px; margin: 0 0.5em; cursor: pointer; display: inline-block; }

input.in-radio:checked + label:before,
input.in-radio:not(:checked) + label:before {
    content: ''; position: absolute; left: -2px; top: 1px; width: 20px; height: 20px; border: 2px solid #0F3250; border-radius: 100%;
    background: #fff;
}
input.in-radio:checked + label:after,
input.in-radio:not(:checked) + label:after {
    content: ''; width: 12px; height: 12px; background: #0F3250; position: absolute;
    top: 5px; left: 2px; border-radius: 100%; -webkit-transition: all 0.2s ease; transition: all 0.2s ease;
}
input.in-radio:not(:checked) + label:after {
    opacity: 0; -webkit-transform: scale(0); transform: scale(0);
}
input.in-radio:not(disabled):checked + label:after {
    opacity: 1; -webkit-transform: scale(.65); transform: scale(.65);
}
/// SLIDER HACKS - Firefox /////
@supports(-moz-appearance: none) {
    input::-moz-range-thumb {
        appearance: none; width: 20px; height: 20px;
        background-image: url(../assets/img/slider_thumb_fill.png); background-size: 100%;
        transform: translateX(-50%); cursor: pointer; border: none;
        @include tablet-portrait { transform: translateX(-50%); }
        &:hover { background-position-x: 66.66%; }
    }
    input.slider-disabled::-moz-range-thumb {
        background-position-x: 100%;  cursor: default;
        &:hover { background-position-x: 100%; cursor: default; }
    }
    input.slider-untouched::-moz-range-thumb {
        background-image: url(../assets/img/slider_thumb_outline_dark.png);
        background-position-x: 33.33%; transform: translateX(2.5vw);
        @include tablet-portrait { transform: translateX(1.5vw); }
        @include desktop { transform: translateX(1.2vw); }
        &:hover { background-position-x: 66.66%; }
    }
    input.slider-cant-say::-moz-range-thumb { background: none; }
}

/// SLIDER HACKS///
.mt--questionnaire .question-range input[type="range"] {
    width: 106%;
    @include phone-portrait { width: 104%; }
    @include phone-landscape { width: 103.5%; }
    @include tablet-portrait { width: 106%; }
    @include tablet-landscape { width: 105%; }
    @include desktop { width: 104%; }
}

.slider {
    -webkit-appearance: none; appearance: none; width: 100%; height: 10px; background: transparent; outline: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none; appearance: none; width: 20px; height: 20px;
        background-image: url(../assets/img/slider_thumb_fill.png); background-size: 100%;
        transform: translateX(-50%); cursor: pointer; border: none; 
        /*&:hover { background-position-x: 66.66%; }*/
    }
    &.slider-disabled::-webkit-slider-thumb {
        /*background-position-x: 100%;  cursor: default;*/
        /*&:hover { background-position-x: 100%; cursor: default; }*/
    }
    &.slider-untouched::-webkit-slider-thumb {
        position: relative; left: 5.5%;
        background-image: url(../assets/img/slider_thumb_outline_dark.png);
        /*background-position-x: 33.33%; position: relative; left: 5.5%;*/
        /*&:hover { background-position-x: 66.66%; }*/
    }
    &.slider-cant-say::-webkit-slider-thumb { display: none; } 

}
html[lang=ar] .slider.slider-untouched::-webkit-slider-thumb { left: 0; right: 5.5%;  }

.slider.flip::-webkit-slider-thumb {
     transform: translateX(50%);
}