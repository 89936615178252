.chart .tick {
    display:none; width: 1px; position: absolute; background: $color-grey-2/*$color-bgrey*/; height: 100%; top: 0; /*z-index: 16;*/
    @include phone-portrait { width: 2px; display: block; background: $color-grey-2; }
}
.chart-bar {
    display: none; height: 10px; align-items: center; justify-content: flex-end; position: relative; z-index: 14;
    @include phone-portrait { display: flex; }
    &.bar-short {
        width: 40px; display: flex; justify-content: center; margin-right: 0; padding: 1.0rem; border-radius: 4px;
        @include phone-portrait { display: none; margin-right: 0; }
    }
    &.bar-wide { height: 10px; @include phone-portrait { height: 18px; } }
    &>.bar-value {
        display: none; margin-inline-start: auto; margin-inline-end: auto; font-size: 1em; color: white; font-weight: 400;
        position: relative; top: -1px;
        @include phone-portrait { display: flex; align-items: center; margin-inline-end: 0.75em; }
    }
}
html[lang=ar] .chart-bar .bar-value { font-size: 15px; top: 0; }
/* MINI BAR */
.mini-chart-container { width: 80%; height: 10px; align-self: center; display: inline-block; background-color: #ECEFF1; }
.mini-chart-bar { height: 10px; position: relative; }

/* INDIVIDUAL VALUES BAR */
.chart .line { width: 100%; height: 2px !important; background-color: #697378; position: absolute; z-index: 4; }

.individual-bar {
    .value-indicator {
        position: absolute; bottom: 18px; left: -9px; z-index: 2;
        svg { stroke-width: 10px; }
    }
    .indicator-count { position: absolute; left: 6px; bottom: 18px; text-align: center; font-weight: 600; font-size: 0.9em; }
    
    
    .line-manager-color {
        color: $color-lm-stroke;
        & svg { fill: $color-lm-fill; stroke: $color-lm-stroke; }
    }
    .team-leader-color {
        color: $color-tl-stroke;
        svg { fill: $color-tl-fill; stroke: $color-tl-stroke; fill-opacity: 0.3; }
    }
    .team-member-color {
        color: $color-m-stroke;
        svg { fill: $color-m-fill; stroke: $color-m-stroke; fill-opacity: 0.3; }
    }
    .stakeholder-color {
        color: $color-sh-stroke;
        svg { fill: $color-sh-fill; stroke: $color-sh-stroke; fill-opacity: 0.3; }
    }
}
html[dir="rtl"] .individual-bar {
    .value-indicator { left: unset; right: -9px; }
    .indicator-count { left: unset; right: 6px; }
}

.individual-bar-short {
    display: block; text-align: center; padding: 0.25rem 0.5rem; background: $color-lgrey; border-radius: 4px; 
    @include phone-portrait { display: none; } 

    &.line-manager-color { background: $color-lm-fill; }
    &.team-leader-color { background: $color-tl-fill; }
    &.team-member-color { background: $color-m-fill; }
    &.stakeholder-color { background: $color-sh-fill; }
}