// Metateam 
$color-blue: #0F3250;
$color-dblue: #0B253C;
$color-grey: grey;
$color-lgrey: lightgrey;
$color-dgrey: #333;
$color-bgrey: #ECEFF1;
$color-bluegrey: #5E768A;
$color-orange: #FF9900;
$color-orange-2: #FFC266;
$color-borange: #FFDAA3;

$color-txt-lgrey: #697378;

$color-blue-1: #CAD2D8;
$color-grey-1: #E8ECEE;
$color-grey-2: #BDBDBD;
$color-grey-3: #959595;
$color-grey-4: #CFD6DC;
$color-grey-5: #DAE0E5;
$color-grey-6: #DEDEDE;

$color-dgreen: #003C14;
$color-green: #406D4F;
$color-lgreen: #8CA795;
$color-bgreen: #C7D4CB;

/// AREA COLORS ///
.mt--fill-white { background-color: white; }
.mt--fill-grey { background-color: $color-grey;  }
.mt--fill-bgrey { background-color: $color-bgrey; }
.mt--fill-dgrey { background-color: $color-dgrey; }
.mt--fill-lgrey { background-color: $color-lgrey; }
.mt--fill-blue { background-color: $color-blue; }
.mt--fill-dblue { background-color: $color-dblue; }
.mt--fill-bgreen { background-color: $color-bgreen; }

.tint-orange { color: $color-orange; }
.tint-grey   { color: $color-bgrey; }
.tint-grey-2 { color: $color-grey; }
.tint-chk-grey { color: #B0BBC5; }
.tint-txt-grey { color: $color-txt-lgrey; }
.tint-txt-dgrey { color: #222121; } 
.tint-green  { color: $color-dgreen!important; }
.tint-blue   { color: $color-blue!important; }
.tint-white  { color: white!important; }
//CHART COLORS

//MHD
$color-tl-fill: #005DE8;
$color-tl-stroke: #004488;
$color-m-fill: #50A5EE;
$color-m-stroke: #50A5EE;
$color-sh-fill: #FF9900;
$color-sh-stroke: #FF9900;
$color-lm-fill: #003C14;
$color-lm-stroke: #003C14;

// KEYS //
/*$color-key-low:    hsla(0, 90%, 55%, 0.85);
$color-key-medium: hsla(35, 95%, 50%, 0.85);
$color-key-good:   hsla(50, 100%, 47%, 0.85);
$color-key-best:   hsla(100, 50%, 50%, 0.85);*/

$color-key-best: #87C367;
$color-key-good: #FAD224;
$color-key-medium: #FEAD28;
$color-key-low: #FE6056;

$color-traffic-red: red;
$color-traffic-yellow:  $color-orange;
$color-traffic-green: #406D4F;


// UTILS
.mt--dim-1 { opacity: 0.67; }

// Colors - KP colors
// Generic colors
$palette-black: #222020; // Black (darkest grey)
$palette-all3s: #333333; // Generic dark grey
$palette-dgrey: #4C5153; // Dark grey color
$palette-mgrey: #727272; // Medium grey color
$palette-egrey: #BDBDBC; // Earl grey :)
$palette-bgrey: #E0E0E0; // Bright grey color
$palette-lgrey: #F2F2F2; // Light grey color
$palette-nearw: #F9F8F7; // Near white color
$palette-white: #FFFFFF; // Full white
$palette-alert: #D6083B; // Generic alert color
$palette-warng: #F8B200; // Generic warning color
$palette-green: #77B705; // Generic green color
$palette-amber: #F8B200; // Results amber color
$palette-lgree: #88CC22; // Results light green color
$palette-dgree: #008844; // Results dark green color

// Base colors
$palette-brand-core: #240F6E; // KP Deep Blue
$palette-brand-pri1: #005DE8; // KP Light Blue
$palette-brand-pri2: #082ECF; // KP Mid Blue
$palette-brand-sec1: #E5F4FA; // Light Blue
$palette-brand-sec2: #F5F8FA; // Light Blue 10%

// MyLearning colors
$palette-learning-1: #4E9D2D; // Deep Green
$palette-learning-2: #77B705; // Light Green
$palette-learning-3: #E9F3D7; // Light Green 10%

// MyCoaching colors
$palette-coaching-1: #F8B200; // Deep Yellow
$palette-coaching-2: #FFC82E; // Light Yellow

// MyResults colors
$palette-myresult-1: #E55302; // Orange

// Manager's view colors
$palette-managers-1: #D6083B; // KP Red


