.backdrop-color-50 {
    background-color: rgba(0, 0, 0, 0.5);
}
.backdrop-color-67 {
    background-color: rgba(0,0,0, 0.67);
}
.backdrop-color-transparent {
    background-color: rgba(0, 0, 0, 0);
}
.backdrop-zindex-100 {
    z-index: 100;
}
.backdrop-zindex-1 {
    z-index: 1;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}

.info-modal {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
}
.action-modal {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
}

.info-modal-content {
    background-color: white;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
    box-sizing: border-box;
    max-width: 600px;
    // min-width: 320px;
    width: inherit;
}

.info-modal-header {
    background-color: #0B253C;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.info-modal-title {
     padding: 0.75rem;
}

.info-modal-close {
    color: #0B253C;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0.75rem;
}

.info-modal-border {
    border-top: 1px solid transparent;
}
.info-modal-body {
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem 1rem 1.5rem;
    justify-content: space-between;
}
.info-modal-button {
    align-self: center;
    margin: 1rem;
}
.info-modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button { 
        margin: .5rem;
        svg { margin-right: 0.5rem;}
    }
}

.pointer {
    cursor: pointer;
}

/*@media (min-width: 600px) {
    .info-modal {
        width: 400px;
        left: calc(50% - 200px);
    }
}*/
@media (max-width: 450px) {
    .info-modal-buttons {
        flex-direction: column;
    }
}
