/// ADMIN ///
.mt--admin {

    .mt--lead {
        width: 100%; display: flex; align-items: center; justify-content: space-between;
        color: $color-dblue; font-size: 2.2rem; padding-left: 0; padding-right: 0; padding-top: 2rem; padding-bottom: 2rem;
        @include phone-portrait { padding-left: $paddin-sides-close; padding-right: $paddin-sides-close }
        @include tablet-portrait { padding-left: $paddin-sides-loose; padding-right: $paddin-sides-loose }
        div { font-size: 2.2rem; font-weight: 600; padding-inline-end: 2rem;  @include tablet-portrait {padding-inline-end: 8rem;} }
    }
    
    .admin-subtitle {
        font-size: 1.2rem; color: white; align-self: center; font-weight: 100; width: 55%; justify-content: flex-end;
        @include phone-portrait { width: auto; font-size: 1.467rem;}
    }
 
    .mt--navbar-secondary {
        display: none; padding-bottom: 1.5rem; min-height: initial;
        @include tablet-landscape { display: flex; font-size: 1rem; }
    }
    
    .mt--panel-overall {
        background: $color-bgrey; padding: 1rem 0; display: flex; justify-content: space-between; flex-wrap: wrap;
        @include phone-landscape { padding: 3rem 0; }
        &>div {
            width: 50%; margin: 1rem 0;
            @include phone-portrait { width: 50%; }
            @include phone-landscape { width: 30%; }
        }

        div div:first-child { color: $color-blue; font-weight: 600; }
    }
    .mt--panel-btn {
        display: flex; justify-content: center; margin-top: -3rem;
        button { max-width: 32rem; }
    }

    .mt--navbar-secondary .mt--navbar-nav {
        li a { padding: 0.5em 1.5em 0.5em 1em; }
        li:last-child a { margin-right: 0; }
    }
    
    .mt--dashboard {
        .dashboard-tiles-wrap {
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(auto-fit,minmax(280px, 1fr));
            
            .dashboard-tile {
                border: 4px solid #D5D5D5;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                height: 420px;
                padding: 2rem;
                padding-top: 2.5rem;

                .tile-title {
                    color: $color-dblue;
                    font-size: 1.4rem;
                    font-weight: 600;
                }
                .tile-subtitle {
                    color: #222121;
                    font-size: 1.0667rem;
                    margin-top: 0.5rem;
                }
                .tile-icon {
                    align-items: center;
                    display: flex;
                    height: 60px;
                    margin-bottom: 1.5rem;
                    width: 80px;
                }

                .tile-link {
                    color: $color-dblue;
                    font-weight: bold;
                    margin-top: auto;
                    text-transform : uppercase;
                    
                    a, span {
                        color: inherit;
                        font-weight: bold;
                        margin-inline-end: 0.5rem;
                        &:hover {
                           color: $color-orange;
                        }
                    }
                }
            }

        }
    }

}