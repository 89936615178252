.mt--btn-wrap { display: flex; justify-content: center; margin: 1rem 0; @include phone-landscape {  margin: 2rem 0; } }
.mt--btn-corner {
    display: flex; position: relative;
    background-color: white; color: $color-dgrey;
    font-weight: 400; padding: 0.5em 2em 0.5em 1.5em;
    &.active { background-color: $color-blue; color: white; }
}
.mt--btn-corner-outline {
      display: block; position: relative; padding: 0.5em 1.75em 0.5em 1.15em;/* margin-inline-end: 2rem;*/ overflow: hidden; z-index: 1;
      color: white!important; border-left: 1px solid white; white-space: nowrap;
      &:after, &:before {
      position: absolute; width: 100%; content: ''; left: 0px; z-index: -1;
    }
    &:before {
        width: calc(100% + 2px); height: 55%; top: 0px; border: 2px solid white; border-width: 1px 2px 0px 0px; transform: skew(45deg); transform-origin: right bottom;
    }
    &:after {
      height: 50%; bottom: 0px; border: 2px solid white; border-width: 0px 1px 1px 0px;
    }
    &.active:before, &.active:after {
      background-color: $color-orange; border: none
    }
    &.active { border-left: none; }

    &.outline-orange { border-color: orange; &:before, &:after { border-color: orange; } }
}

@include tablet-landscape {
    .mt--btn-corner.active:before {
        content: ''; position: absolute; width: 0; top: 0; right: 0; border-left: 16px solid $color-blue; border-top: 16px solid white;
    }
}

.mt--navbar-secondary .mt--btn-corner {
  background: $color-dblue; color: white;
  &:before {
      content: ''; position: absolute; width: 0; top: 0; right: 0; border-left: 16px solid $color-dblue; border-top: 16px solid $color-blue;
  }
  &.active {
    background: $color-orange !important;
    &:before {
      background: $color-orange; border-left-color: $color-orange; border-top-color: $color-blue;
      content: ''; position: absolute; width: 0; top: 0; right: 0;
    }
  }
}
html[dir=rtl] {
    .mt--navbar-secondary .mt--btn-corner {
      &:before {
          right: unset; left: 0; border-left: none; border-right: 16px solid $color-dblue;
      }
      &.active {
        &:before {
          background: $color-orange; border-right-color: $color-orange; border-top-color: $color-bgrey;
          right: unset; left: 0;
        }
      }
    }
     .mt--navbar-secondary .mt--btn-corner-outline {
         border-left: 0; border-right: 1px solid white;
         &.active { border-right: 1px solid $color-orange; }
         &:before {
             transform: skew(-45deg); border-width: 1px 2px 0 1px; height: 45.5%;   //rounding errors @ 45% with 75% browser zoom
         }
         &:after { height: 55%; border-width: 0 0 1px 1px; }
     }
}
.mt--pattern-grey .mt--navbar-secondary .mt--btn-corner {
    &:before { border-top-color: $color-bgrey; }
}

.mt--btn {
    font-size: 0.7rem; font-weight: bold; max-width: 30em; margin-top: 1.5em; margin-bottom: 1.5em; padding: 0 2em;
    color: $color-blue; text-align: center; text-transform: uppercase; letter-spacing: 1px; 
    background: white; border: 1px solid $color-blue; border-radius: 2px; cursor: pointer; 
    &.btn-med {
        display: flex; align-items: center; border-radius: 4px; min-width: 100px; padding: 0.85em 2em;
        .mt--ico { padding-inline-end: 0.5em; font-size: 1rem; color: $color-blue; }
        &.wide { min-width: 150px; }
        &.resend { border-radius: 2px; justify-content: center; letter-spacing: 0; margin: .25em 0; min-width: auto; padding: 0.5em; width: 125px; }
        &:hover {
            color: white; 
            i { color: white; }
        }
    }
    i { margin-right: 0.35em; color: $color-blue; }
    &:hover { color: white; background: $color-orange; border-color: $color-orange; }
    &:hover svg, &:hover .mt--ico { color: white; }
    &.disabled, &:disabled {
        color: white; background: $color-grey-4; border: none;
        &:hover, & svg { color: white; cursor: default; }
    }
    &.btn-dx-like {
        height: 42px; color: $color-blue; justify-content: center; border-radius: 2px;
    }
}

.mt--dx-toolbar { position: relative; top: 40px; z-index: 9; margin-top: -2.8rem; width: fit-content; }

.btn-cta {
    display: flex; gap: 1rem; padding: 1rem 1.75rem; background: transparent;
    border: 2px solid $color-orange; border-radius: 8px;
    @include tablet-portrait { padding: 1rem 2.5rem; }
    div { text-align: start; letter-spacing: initial; }
    .txt-main { font-size: 1.4rem; font-weight: bold; text-transform: none; white-space: nowrap; }
    .txt-sub { font-size: 0.8rem; font-weight: 600; }
    .mt--ico { font-size: 2rem; color: $color-orange; }
    &:hover .txt-sub { color: white; }
}
/* html[lang=el] .btn-cta.btn-wide-el .txt-main { white-space: nowrap; } */

.btn-grid-refresh {
    display: flex; align-items: center; border-radius: 4px;
    font-size: 0.7rem; font-weight: bold; max-width: 30em; margin-top: -0.5em; min-width: 100px; padding: 0.85em 2em;
    color: $color-blue; text-align: center; text-transform: uppercase; letter-spacing: 1px; 
    background: white; border: 1px solid $color-blue; border-radius: 2px; cursor: pointer; 
    .mt--ico { padding-inline-end: 0.5em; font-size: 1rem; color: $color-blue; }
}

.mt--btn-linkalike {
    background-color: transparent; border: 0; color: $color-blue; font-weight: 600; margin: 0.5em 0; padding: 0;
    display: flex; align-items: center; justify-content: flex-start; text-align: left;
    span:before { color: $color-orange; font-size: 16px; margin-inline-end: .25em; }
    &:hover { 
        color: $color-orange;
        .mt--linkalike-label { text-decoration: underline; }
    }
}