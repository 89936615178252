// Open Sans - Font files include latin extended characters
@font-face {
    font-family: 'Open-Sans';
    src: url('../assets/fonts/opensans/OpenSans-Light.eot');
    src: url('../assets/fonts/opensans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/opensans/OpenSans-Light.woff') format('woff'),
         url('../assets/fonts/opensans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open-Sans';
    src: url('../assets/fonts/opensans/OpenSans-Regular.eot');
    src: url('../assets/fonts/opensans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/opensans/OpenSans-Regular.woff') format('woff'),
         url('../assets/fonts/opensans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open-Sans';
    src: url('../assets/fonts/opensans/OpenSans-Semibold.eot');
    src: url('../assets/fonts/opensans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/opensans/OpenSans-Semibold.woff') format('woff'),
         url('../assets/fonts/opensans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open-Sans';
    src: url('../assets/fonts/opensans/OpenSans-Bold.eot');
    src: url('../assets/fonts/opensans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/opensans/OpenSans-Bold.woff') format('woff'),
         url('../assets/fonts/opensans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Almarai';
    src: url('../assets/fonts/almarai/Almarai-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Almarai';
    src: url('../assets/fonts/almarai/Almarai-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Almarai';
    src: url('../assets/fonts/almarai/Almarai-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Almarai';
    src: url('../assets/fonts/almarai/Almarai-ExtraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}