//////////// SUMMARY + RADAR CHART //////////////
.summary-container > div:nth-child(1) { order: 3; }
.summary-container > div:nth-child(2) { order: 2; }
.summary-container > div:nth-child(3) { order: 1; }

.summary-radar-wrap {
    margin-top: 2rem; @include desktop { margin-top: 0; }
    .mt--radar { margin: 0 auto; max-width: 640px; overflow: hidden; }
    svg { overflow: visible !important; transform: rotate(-22.5deg); }
}

@media ( min-width: $screen-tablet-xxlscreen-min) {
    .summary-radar-wrap { margin-top: -6em; }
    .summary-container > div:nth-child(1) { order: 1 }
    .summary-container > div:nth-child(2) { order: 2 }
    .summary-container > div:nth-child(3) { order: 3 }
    .radar-selector { margin-top: 0; }
}
.tbl-wrapper {
    padding: 5px; border: 2px solid #E8ECEE; margin-top: 2rem;
    table { width: 100%; border-collapse: collapse; }
    td, th {
        width: 10%; padding: 0.5rem;
        @include phone-portrait { padding: 0.5rem 0.75rem; }
        }
    thead th {
        font-weight: 600; color: $color-txt-lgrey;
        &:first-child { text-align: start; }
    }
    td { color: $color-dgrey; }
    td:first-child { width: 30%; color: $color-blue; font-weight: 600; text-transform: uppercase; @include phone-portrait { width: 40%; } }
    td:not(:first-child) { text-align: center; font-size: 1rem; }
    tr:nth-child(odd) td { background: $color-bgrey; }
    td:last-child { color: $color-blue; font-weight: bold;  }
}
html[lang=ar] .tbl-wrapper td:not(:first-child) { font-size: 0.8667rem; }
html[lang=ar] .tbl-wrapper th:not(:first-child):not(:last-child) { font-size: 0.8667rem; }
@media print { html[lang=ar] .tbl-wrapper th:not(:first-child):not(:last-child) { font-size: 0.7rem; } }


///////////// RESULTS TABLES GENERAL /////////////////
.tbl-row-group {
    padding: 1rem 1rem 1.5rem 1rem; margin-bottom: 4rem;
    @include phone-portrait { padding: 1rem 2rem; }
    @include tablet-landscape { margin-bottom: 0; }
    &:last-child { margin-bottom: 0; }

    .tbl-header-inside .cell {
        &:nth-child(n+4) { font-weight: 600!important; }
        .bar-scale {  width: 99%; margin-left: -3px; }
    }
}
.tbl-row-group:nth-child(even)  { background: $color-bgrey; }
.tbl-row-group:nth-child(odd)  { background: $color-bgrey; @include tablet-landscape { background: white; } }
.tbl-row {
    display: flex; flex-wrap: wrap; align-items: center;
    &:not(.tbl-header) {
        margin-bottom: 2.5rem; @include phone-portrait { margin-bottom: 0; }
        &:nth-child(1) .cell:nth-child(1) {
            display: flex; align-items: center; margin: 0.5rem 0;
            color: $color-dblue; font-weight: bold; text-transform: uppercase;
            &>div {
                cursor: pointer;
                &:hover {color: $color-bluegrey;}
            }
            @include tablet-landscape { margin: 0; min-height: initial; }
        }
        &>.cell:nth-child(2) {
            padding-top: 1rem; padding-bottom: 1rem; line-height: 1.1;
            @include tablet-landscape { padding-top: 0; padding-bottom: 0; border: none; }
        }
    }
    &>.cell:nth-child(1) {
        width: 100%; font-weight: 600;
        @include tablet-landscape { width: 16%; padding-inline-end: 1rem; line-height: 1.2; }
    }
    &>.cell:nth-child(2) {
        width: 100%; padding-inline-end: 1rem;
        @include tablet-portrait { width: 36%; }
        @include tablet-landscape { width: 20%; }
    }
    &>.cell:nth-child(3) {
        width: 100%; flex-grow: 1; 
            @include phone-portrait { width: auto; padding-inline-end: 2rem; }
            @include tablet-landscape {  padding-inline-end: 3rem; }
            @include desktop { padding-inline-end: 3rem; } 
    }
    &>.cell:nth-child(n+4) {
        text-align: start; flex-grow: 1; padding: 0.5rem 0; 
        @include phone-portrait { width: 8.5%; flex-grow: 0; padding: 0; text-align: end; border: none; margin-right: 0;  }
        @include phone-landscape { width: 7%; } 
        @include tablet-portrait { width: 5.5%; }
        @include tablet-landscape { width: 4.5%; }
        
    }

    &.tbl-header {
        padding: 1rem; color: $color-txt-lgrey; font-weight: 600;
        @include phone-portrait { display: flex; padding: 1rem 2rem; }
        &>.cell:nth-child(1) {
            width: 36%; flex-grow: 1;
            @include tablet-portrait { flex-grow: 0; }
            @include tablet-landscape { width: 16%; flex-grow: 0; }
        }
        &>.cell:nth-child(2) { display: none; @include tablet-landscape { display: block; } }
        &>.cell:nth-child(3) { display: none; @include tablet-portrait { display: block; } }
        &>.cell:nth-child(n+4) { display: none; @include phone-portrait { display: block; }  }
    }

    .cell-full { width: 100%!important; }

    .chart {
        width: 100%; position: relative; display: flex; align-items: center; height: auto; background: $color-grey-4;
        @include phone-portrait { height: 35px; background: transparent; }
        /*@include tablet-portrait { height: 50px; }*/
    }
    .bar-scale {
        display: flex; width: 98%; position: relative; font-size: 13px; font-weight: normal; line-height: 0;
        &>div { position: absolute; }
    }

}
//GREEK TBL MODIFIER
html[lang="el"] .tbl-row {
    &>.cell:nth-child(n+4) {
        text-align: start; flex-grow: 1; padding: 0.5rem 0; 
        @include phone-portrait { width: 8.5%; flex-grow: 0; padding: 0; text-align: end; border: none; margin-right: 0;  }
        @include phone-landscape { width: 7%; } 
        @include tablet-portrait { width: 5.5%; }
        @include tablet-landscape { width: 7%; }
    }
}

/// SUMMARY TABLE MODIFIERS ///

.tbl-results-habits .tbl-row {
    &:not(.tbl-header) {
        margin-bottom: 0;
        &>.cell:nth-child(4) { font-weight: bold; }
    }
    &.tbl-header {
        /*&>.cell:nth-child(1) { width: 75%; @include phone-portrait { width: 16%; }  }*/
        /*&>.cell:nth-child(5) { width: 25%; display: block; text-align: right; @include phone-portrait { width: 15%; content: ""; }  }*/
        &>.cell:nth-child(5):after { content:" / Score"; @include phone-portrait { content: ""; } }
        .bar-scale { width: 98%; display: none; @include tablet-landscape { display: block; } }
    }
    &>.cell:nth-child(2) {
        width: 100%;
        @include tablet-landscape { width: 18%;  }
        @include tablet-portrait { width: 20%; }
    }
    &>.cell:nth-child(3) { width: 80%; @include phone-portrait { width:auto ; } }
    &>.cell:nth-child(n+4) { width: 20%; text-align: end; @include phone-portrait { width: 10%; } }
    &>.cell:nth-child(n+5) { display: none; /*width: 15%;*/ @include phone-portrait { display: block; } }
    &:nth-child(n+2)>.cell:first-child { display: none; @include phone-portrait { display: block; } }
    &>.cell:nth-child(n+4):before { content: "";  } 
    .chart-bar { display: flex; }
    .bar-short { display: none; }
    .oa-avg {
        background: $color-orange; color: white; padding: 0.5rem 1rem; margin-inline-start: auto;
        display: block; border-radius: 4px;
        @include phone-portrait { display: none; } }
}
/////// MICROHABITS DETAILS TABLE MODIFIERS ///////
.survey-micro-habits-details-table {
    
    .chart {
        width: 100%; height: 30px; background: transparent; @include phone-portrait { height: 50px; }
        .value-indicator { bottom: 10px; @include phone-portrait{ bottom: 20px; } }
    }
    .bar-short { margin-inline-start: 1rem; @include phone-portrait { margin-left: 0; } }
    .bar-value, .tick  { display: block; }
    .chart-hide-details .tick { display: none; @include phone-portrait { display: block; } }
    .chart-hide-details { display: flex; margin: 1rem 0; @include phone-portrait { margin:0; } }
    //average row
    .tbl-row-group>.tbl-row:first-child {
        margin-bottom: 2rem; 
        &>.cell:nth-child(2) {
            display: none;
            @include phone-portrait { display: block; width: 100%; margin-bottom: 0;}
            @include tablet-portrait { width: 36%; }
            @include tablet-landscape { width: 20%; }
        }
        .cell:nth-child(4) { font-weight: bold; }
    }
    .tbl-row {
        &.tbl-header {
            &>.cell:nth-child(1) { width: 36%; color: $color-dgrey; }
            &>.cell:nth-child(1):after { content: ""; }
            &>.cell:nth-child(2) { display: none; }
            /*&>.cell:nth-child(3) { width: 70%; @include phone-portrait { width: auto; } }*/
            .bar-scale { display: none; @include tablet-portrait { display: block; } }
        }
        &:not(.tbl-header) {
            margin-bottom: 0!important;
            &:first-child .cell:first-child { display: none; @include tablet-landscape { display: block; } }
        }
        
        &>.cell:nth-child(3) { width: 60%; @include phone-portrait { width: auto; } }
        &>.cell:nth-child(4) { display: none; @include phone-portrait { display: block; } }
        &>.cell:nth-child(n+4) { text-align: end; }
        .habit-title { color: $color-blue; font-weight: bold; text-transform: uppercase; }
    }
}
//// MAIN MICROHABITS TABLE MODIFIERS /////

.tbl-results-microhabits .tbl-row {
    .chart { background: $color-grey-4; @include phone-portrait { background: transparent; } }
    .chart-bar {
        display: flex;
        &.bar-short { display: none; }
    }
    .tbl-row { padding-top: 1rem; @include phone-portrait { padding-top: 0; } }
    &:not(.tbl-header) {
        margin-bottom: 0;
        &>.cell:nth-child(3) {
            display: flex; align-items: center; padding-bottom: 1.25rem; 
            @include phone-portrait { padding-top:0; padding-bottom:0; }
        }
        &>.cell:nth-child(4) { font-weight: bold; }
    }
  
    &>.cell:nth-child(n+4) {
        font-size: 15px; border-top: 1px solid $color-lgrey; border-bottom: 1px solid $color-lgrey; margin-bottom: 1rem; 
        &:not(:last-child) { margin-right: 3%; }
        @include phone-portrait  { border: none; margin-bottom: 0; margin-right: 0!important; }     
    }
}
html[lang=ar] .tbl-results-microhabits .tbl-header > .cell:nth-child(n+4) { font-size: 13px; @include desktop { font-size: 15px; } }
html[lang=ar] .survey-micro-habits-details-table .tbl-header > .cell:nth-child(n+4) { font-size: 13px; @include desktop { font-size: 15px; } }